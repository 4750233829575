import React from "react";

const GalleryCom = () => {
  return (
    <section className="padding py-[20px]">
      <div className="flex gap-[16px] justify-center items-center flex-wrap">
        <a
          href="#hotel-view"
          className="text-lightBrown poppins tracking-[0.3px] leading-[22px] py-[10px] border-b-[2px] border-lightBrown"
        >
          Hotel View
        </a>
        <a
          href="#rooms&suits "
          className="text-[#C5BFB1] poppins tracking-[0.3px] leading-[22px] py-[10px] hover:border-b-[2px] hover:border-lightBrown hover:text-lightBrown"
        >
          Rooms & Suits
        </a>
        <a
          href="#recreation&fitness"
          className="text-[#C5BFB1] poppins tracking-[0.3px] leading-[22px] py-[10px] hover:border-b-[2px] hover:border-lightBrown hover:text-lightBrown"
        >
          Recreation & Fitness
        </a>
        <a
          href="#restaurant"
          className="text-[#C5BFB1] poppins tracking-[0.3px] leading-[22px] py-[10px] hover:border-b-[2px] hover:border-lightBrown hover:text-lightBrown"
        >
          Restaurant
        </a>
        <a
          href="#events&meetings"
          className="text-[#C5BFB1] poppins tracking-[0.3px] leading-[22px] py-[10px] hover:border-b-[2px] hover:border-lightBrown hover:text-lightBrown"
        >
          Events & Meeting
        </a>
      </div>

      <section className="pt-[60px]" id="hotel-view">
        <h1 className="sm:text-[31px] text-[25px] mar leading-[37px] text-[#000] mb-[44px]">
          Hotel View
        </h1>

        <div className="flex lg:gap-[17px] gap-[10px] flex-shrink flex-col md:flex-row">
          <img
            src="/images/hotel1.png"
            alt="hotel_image"
            className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover"
          />
          <img
            src="/images/hotel2.png"
            alt="hotel_image"
            className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover"
          />
          <img
            src="/images/hotel3.png"
            alt="hotel_image"
            className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover"
          />
        </div>

        <div className="lg:mt-[17px] mt-[10px] flex flex-col md:flex-row lg:gap-[17px] gap-[10px] flex-shrink">
          <div className="flex flex-col lg:flex-shrink-0 lg:gap-[17px] gap-[10px]">
            <img
              src="/images/hotel4.png"
              alt="hotel"
              className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover"
            />
            <img
              src="/images/hotel5.png"
              alt="hotel"
              className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover"
            />
          </div>

          <img
            src="/images/hotel6.png"
            alt="hotel"
            className="xl:w-[906px] lg:w-[716px] md:w-[617px] w-full xl:h-[622px] lg:h-[518px] md:h-[418px] h-full object-cover"
          />
        </div>
      </section>

      {/* Rooms &  suits*/}

      <section className="pt-[100px]" id="rooms&suits">
        <h1 className="sm:text-[31px] text-[25px] mar leading-[37px] text-[#000] mb-[44px]">
          Rooms & Suits
        </h1>

        <div className="flex lg:gap-[17px] gap-[10px] flex-shrink flex-col md:flex-row">
          <img
            src="/images/hotel7.png"
            alt="hotel_image"
            className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover"
          />
          <img
            src="/images/hotel8.png"
            alt="hotel_image"
            className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover"
          />
          <img
            src="/images/hotel9.png"
            alt="hotel_image"
            className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover"
          />
        </div>

        <div className="lg:mt-[17px] mt-[10px] flex flex-col md:flex-row lg:gap-[17px] gap-[10px] flex-shrink">
          <img
            src="/images/hotel10.png"
            alt="hotel"
            className="xl:w-[906px] lg:w-[716px] md:w-[617px] w-full xl:h-[622px] lg:h-[518px] md:h-[418px] h-full object-cover"
          />
          <div className="flex flex-col lg:flex-shrink-0 lg:gap-[17px] gap-[10px]">
            <img
              src="/images/hotel11.png"
              alt="hotel"
              className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover"
            />
            <img
              src="/images/hotel12.png"
              alt="hotel"
              className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover"
            />
          </div>
        </div>
      </section>

      {/* Fitness */}

      <section className="pt-[100px]" id="recreation&fitness">
        <h1 className="sm:text-[31px] text-[25px] mar leading-[37px] text-[#000] mb-[44px]">
          Recreation & Fitness
        </h1>

        <div className="flex lg:gap-[17px] gap-[10px] flex-shrink flex-col md:flex-row">
          <img
            src="/images/hotel1.png"
            alt="hotel_image"
            className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover"
          />
          <img
            src="/images/news1.png"
            alt="hotel_image"
            className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover"
          />
          <img
            src="/images/hotel2.png"
            alt="hotel_image"
            className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover"
          />
        </div>
      </section>
      {/* Restaurant */}
      <section className="pt-[100px]" id="restaurant">
        <h1 className="sm:text-[31px] text-[25px] mar leading-[37px] text-[#000] mb-[44px]">
          Restaurant
        </h1>

        <div className="flex lg:gap-[17px] gap-[10px] flex-shrink flex-col md:flex-row">
          <img
            src="/images/hotel1.png"
            alt="hotel_image"
            className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover"
          />
          <img
            src="/images/hotel2.png"
            alt="hotel_image"
            className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover"
          />
          <img
            src="/images/hotel10.png"
            alt="hotel_image"
            className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover"
          />
        </div>
      </section>
      {/* Events */}
      <section className="pt-[100px] mb-[50px]" id="events&meetings">
        <h1 className="sm:text-[31px] text-[25px] mar leading-[37px] text-[#000] mb-[44px]">
          Events & Meeting
        </h1>

        <div className="flex lg:gap-[17px] gap-[10px] flex-shrink flex-col md:flex-row">
          <img
            src="/images/hotel4.png"
            alt="hotel_image"
            className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover"
          />
          <img
            src="/images/hotel5.png"
            alt="hotel_image"
            className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover"
          />
          <img
            src="/images/hotel9.png"
            alt="hotel_image"
            className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover"
          />
        </div>
      </section>
    </section>
  );
};

export default GalleryCom;
