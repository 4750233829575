import React from "react";
import Navbar from "../components/Navbar";
import { Link, useParams } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Newsletter from "../components/Newsletter";
import News from "../components/News";

const NewsDetails = () => {
  const { id } = useParams();

  return (
    <section>
      {id == 1 && (
        <section>
          <Navbar pageTitle={"Luxury at Crismon Hotel"} />

          <Header
            classTitle={"about-bg"}
            pageTitle={"Luxury at Crismon Hotel"}
          />
          <section className="padding">
            <p className="py-[30px] text-center text-orange sm:text-[30px] text-[20px] mar leading-[48px]">
              Luxury Hotel
            </p>

            <img
              src="/images/news11.png"
              alt="news"
              className="w-full sm:h-[60vh] h-full object-cover"
            />

            <p className="text-[17px] poppins leading-[22px] tracking-[0.3px] mt-[40px] mb-[10px]">
              <span className="sm:text-[23px] text-[18px] mar font-semibold">
                Luxurious Rooms and Suites{" "}
              </span>
              At Crismon Hotel, we take pride in offering spacious, exquisitely
              furnished rooms and suites that cater to your every need and
              preference. Designed to provide a harmonious blend of comfort and
              elegance, our accommodations feature top-quality furnishings,
              plush bedding, and modern amenities such as flat-screen TVs and
              complimentary Wi-Fi.
            </p>

            <p className="text-[17px] poppins leading-[22px] tracking-[0.3px] mt-[20px] mb-[10px]">
              For those seeking an extraordinary experience, our hotel presents
              a selection of opulent suites, including penthouse and executive
              suites. Indulge in additional luxuries like separate living areas,
              private balconies, and breathtaking city views. Explore our{" "}
              <Link
                to={"/room-details"}
                className="sm:text-[18px] text-[16px] text-orange opacity-[0.8px] hover:opacity-[1] transition hover:underline"
              >
                rooms and suites
              </Link>{" "}
              for a closer look at the lavish options available.
            </p>

            <p className="text-[17px] poppins leading-[22px] tracking-[0.3px] mt-[20px] mb-[10px]">
              <span className="sm:text-[23px] text-[18px] mar font-semibold">
                Exquisite Dining Experiences{" "}
              </span>
              At Crismon Hotel, we believe dining should be an extraordinary
              affair. Our on-site restaurant showcases a tantalizing menu that
              highlights both local and international cuisine. Each dish is
              carefully crafted using the freshest, highest quality ingredients,
              ensuring a culinary journey like no other. For a more elevated
              experience, visit our rooftop bar, where you can savor a range of
              meticulously crafted cocktails, fine wines, and refreshing
              beverages. Complement your drinks with delectable small bites and
              light snacks, all while enjoying panoramic views. Discover more
              about our exceptional dining offerings{" "}
              <Link
                to={"/restaurants"}
                className="sm:text-[18px] text-[16px] text-orange opacity-[0.8px] hover:opacity-[1] transition hover:underline"
              >
                here
              </Link>{" "}
              on our website.
            </p>

            <p className="text-[17px] poppins leading-[22px] tracking-[0.3px] mt-[20px] mb-[10px]">
              <span className="sm:text-[23px] text-[18px] mar font-semibold">
                Exceptional Meetings and Events{" "}
              </span>
              Crismon Hotel caters to the demands of discerning business
              travelers, offering top-notch meeting and event facilities. Our
              hotel boasts a range of well-appointed meeting rooms and event
              spaces equipped with cutting-edge technology and amenities. From
              corporate gatherings to special events, our experienced event
              staff is dedicated to ensuring the success of your occasions.
              Explore our{" "}
              <Link
                to={"/meetings&events"}
                className="sm:text-[18px] text-[16px] text-orange opacity-[0.8px] hover:opacity-[1] transition hover:underline"
              >
                meetings and events
              </Link>{" "}
              to learn more about our exceptional venues.
            </p>

            <p className="text-[17px] poppins leading-[22px] tracking-[0.3px] mt-[20px] mb-[50px]">
              <span className="sm:text-[23px] text-[18px] mar font-semibold">
                Book Your Unforgettable Stay Today{" "}
              </span>
              Elevate your travel experience to extraordinary heights by
              choosing Crismon Hotel for your high-end luxury getaway. With our
              unmatched amenities, stunning accommodations, and impeccable
              service, we are the ultimate destination for discerning travelers.
              Book your stay today and immerse yourself in the unparalleled
              luxury of Crismon Hotel. To learn more about the luxurious
              amenities and experiences we offer, visit our website or contact
              us at{" "}
              <Link
                to={"/"}
                className="sm:text-[18px] text-[16px] text-orange opacity-[0.8px] hover:opacity-[1] transition hover:underline"
              >
                hotel contact information
              </Link>
              .
            </p>
          </section>
          <News />
          <Newsletter />
          <Footer />
        </section>
      )}

      {/* second */}

      {id == 2 && (
        <section>
          <Navbar pageTitle={"Why Choose Crismon Hotel"} />

          <Header
            classTitle={"about-bg"}
            pageTitle={"Why Choose Crismon Hotel"}
          />
          <section className="padding">
            <p className="py-[30px] text-center text-orange sm:text-[30px] text-[20px] mar leading-[48px]">
              Luxury Hotel
            </p>

            <img
              src="/images/explore-bg3.png"
              alt="news"
              className="w-full sm:h-[60vh] h-full object-cover"
            />

            <p className="text-[17px] poppins leading-[22px] tracking-[0.3px] mt-[40px] mb-[10px]">
              When it comes to indulging in unparalleled luxury and experiencing
              exceptional hospitality, there’s no better choice than Crismon
              Hotel. As a premier luxury hotel, we pride ourselves on offering
              an unforgettable experience to our esteemed guests. From exquisite
              accommodations to high-end amenities and fine dining, here’s why
              Crismon Hotel should be your top choice:
              <br />
              <br />
              <span className="sm:text-[23px] text-[18px] mar font-semibold">
                Luxury Accommodations:{" "}
              </span>
              Crismon Hotel provides an extraordinary selection of spacious and
              beautifully appointed rooms and suites. Each accommodation is
              thoughtfully designed to offer comfort, elegance, and
              functionality. Featuring high-quality furnishings, plush bedding,
              and modern amenities, our rooms provide a serene sanctuary for
              relaxation and rejuvenation.
            </p>

            <p className="text-[17px] poppins leading-[22px] tracking-[0.3px] mt-[20px] mb-[10px]">
              <span className="sm:text-[23px] text-[18px] mar font-semibold">
                High-End Amenities:{" "}
              </span>
              At Crismon Hotel, we understand that luxury extends beyond the
              physical space. Our hotel is equipped with a range of high-end
              amenities to enhance your stay. From state-of-the-art fitness
              centers to rejuvenating spa facilities, we offer everything you
              need to unwind and indulge. Enjoy access to top-notch fitness
              equipment, pampering spa treatments, and wellness services
              tailored to your well-being.
            </p>

            <p className="text-[17px] poppins leading-[22px] tracking-[0.3px] mt-[20px] mb-[10px]">
              <span className="sm:text-[23px] text-[18px] mar font-semibold">
                Fine Dining:{" "}
              </span>
              Indulge your taste buds with our exceptional culinary offerings.
              Our on-site restaurant features a menu that showcases the best in
              local and international cuisine. Prepared with the freshest and
              highest quality ingredients, each dish is a culinary masterpiece.
              Savor the flavors and enjoy a remarkable dining experience at
              Crismon Hotel.
            </p>

            <p className="text-[17px] poppins leading-[22px] tracking-[0.3px] mt-[20px] mb-[10px]">
              <span className="sm:text-[23px] text-[18px] mar font-semibold">
                Exceptional Service{" "}
              </span>
              At Crismon Hotel, we take pride in delivering exceptional service
              to our guests. Our dedicated and professional staff is committed
              to ensuring your every need is met with personalized attention and
              care. From personalized concierge services to prompt and efficient
              room service, we go above and beyond to create a memorable and
              seamless stay for each guest.
            </p>

            <p className="text-[17px] poppins leading-[22px] tracking-[0.3px] mt-[20px] mb-[50px]">
              Experience the epitome of luxury and exceptional hospitality by
              choosing Crismon Hotel for your next getaway. Book your stay with
              us today and immerse yourself in the world of unforgettable luxury
              and exceptional service.
            </p>

            <p className="text-[17px] poppins leading-[22px] tracking-[0.3px] mt-[20px] mb-[10px]">
              To learn more about our luxury accommodations, high-end amenities,
              and fine dining options, visit our{" "}
              <Link
                to={"/"}
                className="sm:text-[18px] text-[16px] text-orange opacity-[0.8px] hover:opacity-[1] transition hover:underline"
              >
                website
              </Link>{" "}
              and discover the exceptional experiences that await you.
            </p>
          </section>
          <News />
          <Newsletter />
          <Footer />
        </section>
      )}

      {/* Third */}

      {id == 3 && (
        <section>
          <Navbar pageTitle={"FAQ about Crismon Hotel"} />

          <Header
            classTitle={"about-bg"}
            pageTitle={"FAQ about Crismon Hotel"}
          />
          <section className="padding">
            <p className="py-[30px] text-center text-orange sm:text-[30px] text-[20px] mar leading-[48px]">
              FAQ, Luxury Hotel
            </p>

            <img
              src="/images/news33.png"
              alt="news"
              className="w-full sm:h-[60vh] h-full object-cover"
            />

            <p className="text-[17px] poppins leading-[22px] tracking-[0.3px] mt-[40px] mb-[10px]">
              Welcome to Crismon Hotel, where luxury and elegance meet to create
              an unforgettable experience. We understand that you may have
              questions about our accommodations, amenities, and services. To
              assist you in planning your extraordinary stay, we have compiled a
              list of frequently asked questions about Crismon Hotel:
            </p>

            <p className="text-[17px] poppins leading-[22px] tracking-[0.3px] mt-[20px] mb-[10px]">
              <span className="sm:text-[23px] text-[18px] mar font-semibold">
                1. What types of luxury accommodations does Crismon Hotel offer?{" "}
              </span>
              Crismon Hotel offers a wide range of luxury accommodations,
              including beautifully appointed rooms and suites. Each space is
              meticulously designed to provide comfort, style, and a truly
              remarkable experience.
            </p>

            <p className="text-[17px] poppins leading-[22px] tracking-[0.3px] mt-[20px] mb-[10px]">
              <span className="sm:text-[23px] text-[18px] mar font-semibold">
                2. What dining options are available at Crismon Hotel?{" "}
              </span>
              Indulge in exquisite culinary experiences at Crismon Hotel. Our
              on-site restaurant showcases the finest local and international
              cuisine, prepared with the freshest ingredients and served with
              impeccable attention to detail. We guarantee a gastronomic journey
              that will delight your taste buds.
            </p>

            <p className="text-[17px] poppins leading-[22px] tracking-[0.3px] mt-[20px] mb-[50px]">
              <span className="sm:text-[23px] text-[18px] mar font-semibold">
                3. How is the service at Crismon Hotel?{" "}
              </span>
              At Crismon Hotel, we are dedicated to providing exceptional
              service that exceeds your expectations. Our attentive staff
              members are committed to ensuring your comfort and satisfaction
              throughout your stay. From personalized concierge services to
              efficient room service, we strive to make your experience
              unforgettable.
            </p>
            <p className="text-[17px] poppins leading-[22px] tracking-[0.3px] mt-[20px] mb-[50px]">
              <span className="sm:text-[23px] text-[18px] mar font-semibold">
                4. What other amenities can I expect during my stay?{" "}
              </span>
              In addition to luxurious accommodations and fine dining, Crismon
              Hotel offers a range of other amenities to enhance your
              experience. Enjoy access to well-equipped fitness facilities,
              where you can maintain your workout routine. Immerse yourself in
              the captivating surroundings, with stunning city views from select
              rooms and suites.
            </p>
            <p className="text-[17px] poppins leading-[22px] tracking-[0.3px] mt-[20px] mb-[50px]">
              <span className="sm:text-[23px] text-[18px] mar font-semibold">
                5. How can I book my luxury experience at Crismon Hotel?{" "}
              </span>
              Booking your luxury experience at Crismon Hotel is convenient and
              effortless. Visit our website at{" "}
              <Link
                to={"https://www.crismonhotel.com/"}
                className="sm:text-[18px] text-[16px] text-orange opacity-[0.8px] hover:opacity-[1] transition hover:underline"
              >
                https://www.crismonhotel.com/
              </Link>{" "}
              to explore our accommodations, amenities, and availability. Our
              user-friendly online booking system allows you to secure your
              reservation with ease.
            </p>
            <p className="text-[17px] poppins leading-[22px] tracking-[0.3px] mt-[20px] mb-[50px]">
              <span className="sm:text-[23px] text-[18px] mar font-semibold">
                6. Are there any special packages or promotions available?{" "}
              </span>
              Crismon Hotel frequently offers special packages and promotions to
              enhance your luxury experience. Be sure to check our website for
              the latest offers tailored to make your stay even more memorable.
            </p>

            <p className="text-[17px] poppins leading-[22px] tracking-[0.3px] mt-[20px] mb-[50px]">
              We hope these frequently asked questions have provided you with
              valuable insights into the world of Crismon Hotel. For more
              information and to book your luxury experience, visit our website
              at{" "}
              <Link
                to={"https://www.crismonhotel.com/"}
                className="sm:text-[18px] text-[16px] text-orange opacity-[0.8px] hover:opacity-[1] transition hover:underline"
              >
                https://www.crismonhotel.com/
              </Link>{" "}
              . Our dedicated team looks forward to welcoming you to a truly
              exceptional stay. to explore our accommodations, amenities, and
              availability. Our user-friendly online booking system allows you
              to secure your reservation with ease.
            </p>
          </section>
          <News />
          <Newsletter />
          <Footer />
        </section>
      )}
    </section>
  );
};

export default NewsDetails;
