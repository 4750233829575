import React from "react";
import { RecentNewsData } from "../constants/data";
import { Link } from "react-router-dom";

const News = () => {
  return (
    <section className="padding py-[78px]">
      <h1 className="md:text-[49px] sm:text-[35px] text-[30px] mar text-orange sm:mb-[60px] mb-[20px] leading-[58px]">
        Recent News
      </h1>

      <section className="sm:scrollbar1 flex gap-[24px] overflow-auto pb-[60px] flex-col sm:flex-row">
        {RecentNewsData.map((data) => (
          <div key={data.id}>
            <div className="sm:w-[400px]">
              <img
                src={data.image}
                alt="news_image"
                className="h-[366px] object-cover"
              />

              <div className="p-[10px]">
                <p className="opacity-[0.6] text-[#000] poppins text-[16px] leading-[22px] tracking-[0.3px]">
                  {data.heading}
                </p>

                <h2 className="mt-[9px] text-[#000] text-[22px] poppins leading-[30px] mb-[16px]">
                  {data.title}
                </h2>

                <p className="text-lightBrown text-[16px] leading-[22px] tracking-[0.3px] poppins mb-[13px]">
                  {data.content}
                </p>
                <Link
                  to={`/news/${data.id}`}
                  className="opacity-[0.6] text-[#000] text-[16px] poppins underline leading-[25px] tracking-[0.3px]"
                >
                  Read more
                </Link>
              </div>
            </div>
          </div>
        ))}
      </section>
    </section>
  );
};

export default News;
