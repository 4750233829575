import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-[#150000]">
      <section className="padding pt-[55px] pb-[63px] flex gap-[1rem] justify-between flex-wrap">
        <div>
          <Link to={"/"}>
            <img
              src="/images/footer-logo.svg"
              alt="logo"
              className="w-[91px] h-[80px]"
            />
          </Link>
          <p className="mt-[42px] text-gray_200 text-[16px] leading-[22px] poppins">
            Crismon Hotel, renowned as one of the best hotels in <br />
            Ghana. Situated in Tema Community 5 on 37 Tench <br />
            Street, this hidden gem offers a touch of luxury at a<br />{" "}
            competitive price. With outstanding amenities.
          </p>
        </div>
        <div className="">
          <p className="text-white uppercase font-semibold poppins text-[20px] tracking-[0.3px] leading-[32px] mb-[12px]">
            Menu
          </p>
          <div className="flex flex-col gap-[9px]">
            <Link
              to={"/"}
              className="text-gray_200 tracking-[0.3px] poppins text-[16px] leading-[22px] hover:text-white transition"
            >
              Home
            </Link>
            <Link
              to={"/about"}
              className="text-gray_200 tracking-[0.3px] poppins text-[16px] leading-[22px] hover:text-white transition"
            >
              About Us
            </Link>
            <Link
              to={"/gallery"}
              className="text-gray_200 tracking-[0.3px] poppins text-[16px] leading-[22px]  hover:text-white transition"
            >
              Gallery
            </Link>
            <Link
              to={"/booking"}
              className="text-gray_200 tracking-[0.3px] poppins text-[16px] leading-[22px] hover:text-white transition"
            >
              Bookings
            </Link>
            <Link
              to={"/blog"}
              className="text-gray_200 tracking-[0.3px] poppins text-[16px] leading-[22px] hover:text-white transition "
            >
              News
            </Link>
            <Link
              to={"/contact-us"}
              className="text-gray_200 tracking-[0.3px] poppins text-[16px] leading-[22px] hover:text-white transition"
            >
              Contact Us
            </Link>
          </div>
        </div>
        <div className="">
          <p className="text-white uppercase font-semibold poppins text-[20px] tracking-[0.3px] leading-[32px] mb-[12px]">
            Services
          </p>

          <div className="flex flex-col gap-[9px]">
            <Link
              to={"/booking"}
              className="text-gray_200 tracking-[0.3px] poppins text-[16px] leading-[22px] hover:text-white transition"
            >
              Crismon Rooms & Suits
            </Link>
            <Link
              to={"/booking"}
              className="text-gray_200 tracking-[0.3px] poppins text-[16px] leading-[22px] hover:text-white transition"
            >
              C&M(Short stays & <br />
              Rentals)
            </Link>
          </div>

          <p className="text-white uppercase font-semibold poppins text-[20px] tracking-[0.3px] leading-[32px] mb-[12px] mt-[41px]">
            Restaurant
          </p>

          <div className="flex flex-col gap-[9px]">
            <Link
              to={"/restaurant"}
              className="text-gray_200 tracking-[0.3px] poppins text-[16px] leading-[22px] hover:text-white transition"
            >
              Restaurant
            </Link>
            <Link
              to={"/about"}
              className="text-gray_200 tracking-[0.3px] poppins text-[16px] leading-[22px] hover:text-white transition"
            >
              View Menu
            </Link>
          </div>
        </div>
        <div>
          <p className="text-white uppercase font-semibold poppins text-[20px] tracking-[0.3px] leading-[32px] mb-[12px]">
            Restaurant
          </p>
          <p className="text-white font-semibold poppins text-[20px] tracking-[0.3px] leading-[32px] mb-[12px]">
            Bookings/Reservations
          </p>
          <p className="text-gray_200 tracking-[0.3px] poppins sm:text-[16px] text-[14px] leading-[22px]">
            info@crismonhotel.com <br />
            reservations@crismonhotel.com
          </p>

          <div className="pt-[53px] flex gap-[20px] flex-wrap">
            <div className="w-[210px]">
              <p className="text-white font-semibold poppins text-[20px] tracking-[0.3px] leading-[32px] mb-[12px]">
                Office line
              </p>
              <p className="text-gray_200 tracking-[0.3px] poppins text-[16px] leading-[22px] ">
                (+233) 30 320 5547 <br />
                (+233) 30 320 5680
                <br />
                (+233) 30 321 1942
              </p>
            </div>

            <div>
              <p className="text-white font-semibold poppins text-[20px] tracking-[0.3px] leading-[32px] mb-[12px]">
                WhatsApp/Call
              </p>
              <p className="text-gray_200 tracking-[0.3px] poppins text-[16px] leading-[22px] ">
                (+233) 56 163 3542
              </p>
            </div>
          </div>

          <div className="flex gap-[23px] mt-[53px] flex-wrap">
            <p className="text-[#585858] tracking-[0.3px] poppins text-[16px] leading-[22px]">
              Follow us:
            </p>
            <div className="flex gap-[10px]">
              <a
                target="__blank"
                href="https://www.tripadvisor.com/Hotel_Review-g1202780-d2578692-Reviews-Crismon_Hotel-Tema_Greater_Accra.html
              "
              >
                <img
                  src="/images/trip-white.svg"
                  alt="social_icon"
                  className="w-[24px] h-[24px] cursor-pointer transition social_icon"
                />
              </a>
              <a
                target="__blank"
                href="https://web.facebook.com/crismonhotelgh/
              "
              >
                <img
                  src="/images/facebook-white.svg"
                  alt="social_icon"
                  className="w-[24px] h-[24px] cursor-pointer transition social_icon"
                />
              </a>

              <a
                target="__blank"
                href="https://www.linkedin.com/company/crismon-hotel-ghana/?originalSubdomain=gh
              "
              >
                <img
                  src="/images/linkedin-white.svg"
                  alt="social_icon"
                  className="w-[24px] h-[24px] cursor-pointer transition social_icon"
                />
              </a>
              <a
                target="__blank"
                href="https://twitter.com/crismonhotelgh
              "
              >
                <img
                  src="/images/twitter-white.svg"
                  alt="social_icon"
                  className="w-[24px] h-[24px] cursor-pointer transition social_icon"
                />
              </a>
              <a
                target="__blank"
                href="https://wa.me/233561633542
              "
              >
                <img
                  src="/images/whatsapp-white.svg"
                  alt="social_icon"
                  className="w-[24px] h-[24px] cursor-pointer transition social_icon"
                />
              </a>
              <a
                target="__blank"
                href="https://www.youtube.com/channel/UCIJ21cmdvVauIByMF_DdHHg?app=desktop
              "
              >
                <img
                  src="/images/youtube-white.svg"
                  alt="social_icon"
                  className="w-[24px] h-[24px] cursor-pointer transition social_icon"
                />
              </a>
            </div>
          </div>
        </div>
      </section>
      <div className="md:px-[61px] px-[30px]">
        <div className="h-[1px] w-full bg-gray_300"></div>
      </div>

      <section className="pt-[40px] pb-[40px] flex gap-[2rem] flex-wrap justify-between items-center md:px-[60px] px-[20px]">
        <p className="text-[#585858] text-[16px] leading-[22px] poppins tracking-[0.3px]">
          ©2023 Crismon Hotel. All Rights Reserved
        </p>

        <div className="flex items-center gap-[34px] flex-wrap">
          <Link to={"/privacy-policy"}>
            <p className="text-[#585858] text-[16px] leading-[22px] poppins tracking-[0.3px] hover:text-white transition">
              Privacy Policy
            </p>
          </Link>
          <Link to={"/terms-and-conditions"}>
            <p className="text-[#585858] text-[16px] leading-[22px] poppins tracking-[0.3px] hover:text-white transition">
              Terms & Conditions
            </p>
          </Link>
          <Link to={"https://simpliexpand.com/"} target="__blank">
            <p className="text-[#585858] text-[16px] leading-[22px] poppins tracking-[0.3px] hover:text-white transition">
              Designed by Simpliexpand
            </p>
          </Link>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
