import React from "react";

const Header = ({ classTitle, pageTitle }) => {
  return (
    <>
      <section
        className={`md:px-[60px] sm:px-[30px] px-[1rem] ${classTitle} h-[273px] flex items-end`}
      >
        <h1 className="mb-[24px] text-white mar sm:text-[49px] text-[35px] sm:leading-[58px] leading-[30px] font-normal">
          {pageTitle}
        </h1>
      </section>
      <div className="line"></div>
    </>
  );
};

export default Header;
