import React from "react";
import Navbar from "../components/Navbar";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import Form from "../components/Form";
import Header from "../components/Header";
import BookingData from "../components/BookingData";

const Booking = () => {
  return (
    <div>
      <Navbar pageTitle={"Booking"} />
      <Header pageTitle={"Rooms/Suits Available"} classTitle={"booking-bg"} />
      <Form />
      <BookingData />
      <Newsletter />
      <Footer />
    </div>
  );
};

export default Booking;
