import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Header from "../components/Header";
import RestaurantHero from "../components/RestaurantHero";
const Restaurant = () => {
  return (
    <div>
      <Navbar pageTitle={"Restaurant"} />
      <Header pageTitle={"Restaurant"} classTitle={"events-bg"} />
      <RestaurantHero />
      <Footer />
    </div>
  );
};

export default Restaurant;
