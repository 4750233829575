import React from "react";
import { bookingData } from "../constants/data";
import { Link } from "react-router-dom";

const BookingData = () => {
  return (
    <section className="padding bg-[#F0EEEB] pt-[92px] pb-[63px]">
      <section className="flex flex-wrap gap-[24px]">
        {bookingData.map((item) => (
          <div key={item.id}>
            <div className="sm:w-[405px] w-full">
              <img
                src={item.image}
                alt={item.title}
                className="w-full sm:h-[366px] h-full object-cover"
              />

              <div className="sm:px-[16px] sm:py-[20px] py-[10px]">
                <div className="flex justify-between items-center flex-wrap sm:gap-[0] gap-[1rem]">
                  <p className="text-[#000] poppins sm:text-[25px] text-[20px] sm:leading-[30px] leading-[20px] sm:w-[60%] w-full">
                    {item.title}
                  </p>
                  <div>
                    <p className="text-[#8F8367] leading-[30px] poppins sm:text-[25px] text-[18px] sm:text-right">
                      {item.price}
                    </p>
                    <p className="text-[#000] opacity-[0.6] text-[13px] poppins tracking-[0.3px] leading-[20px]">
                      {" "}
                      +Tax and fees
                    </p>
                  </div>
                </div>

                <p className="opacity-[0.6] text-[#000] sm:text-[16px] text-[14px] poppins tracking-[0.3px] leading-[25px] underline sm:mt-[16px] mt-[10px] hover:opacity-[1] transition">
                  <Link to={item.path}>View Details</Link>
                </p>
              </div>
            </div>
          </div>
        ))}
      </section>
    </section>
  );
};

export default BookingData;
