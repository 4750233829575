import React from "react";
import { servicesData } from "../constants/data";

const Services = () => {
  return (
    <section className="paddingLeft bg-light pb-[70px]">
      <p className="pt-[78px] mb-[20px] uppercase text-lightBrown_100 sm:text-[16px] text-[14px] leading-[19px] poppins">
        Services Highlights
      </p>
      <h2 className="text-deepBrown_100 sm:text-[49px] text-[35px] mar sm:leading-[58px]  leading-[35px] font-normal sm:mb-[60px] mb-[30px]">
        Discover the essence our hospitality
        <br className="md:flex hidden" /> through our services
      </h2>

      <section className="flex overflow-auto w-full scrollbar ">
        {servicesData.map((data) => (
          <div key={data.id} className="pb-[100px] sm:mr-[52px] mr-[25px]">
            <div className="w-[380px]">
              <img
                src={data.icon}
                alt="icon"
                className="sm:mb-[20px] mb-[10px] sm:w-[62px] sm:h-[62px] w-[40px] h-[40px] object-contain"
              />
              <div className="w-full h-[1px] bg-[#D1CBC0] sm:mb-[18px] mb-[10px]"></div>
              <h2 className="mb-[13px] text-darkBrown sm:text-[25px] text-[20px] poppins leading-[30px] font-normal">
                {data.title}
              </h2>
              <p className="sm:mb-[18px] mb-[15px] text-darkBrown sm:text-[16px] text-[15px] poppins tracking-[0.3px] leading-[22px] opacity-[0.6]">
                {data.content}
              </p>
              <div className="w-full h-[1px] bg-[#D1CBC0]"></div>
            </div>
          </div>
        ))}
      </section>
    </section>
  );
};

export default Services;
