import React from "react";
import Navbar from "../components/Navbar";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import Slider from "../components/Slider";
import RoomDetailsData from "../components/RoomDetailsData";

const RoomDetails5 = () => {
  return (
    <div>
      <Navbar pageTitle={"Room & Suits"} />
      <Slider />
      <RoomDetailsData roomName="Classic Poolside Room" price={"$155"} />
      <Newsletter />
      <Footer />
    </div>
  );
};

export default RoomDetails5;
