import React, { useRef, useState } from "react";
import { service1, service2, service3, service4 } from "../constants/data";
import FormMobile from "./FormMobile";
import { rooms2 } from "../constants/rooms";
import handleBooking from "../utils/handleBooking";

const RoomDetailsData = ({ roomName, price, adult_max = 2 }) => {

  const preSelectRoom = () => {
    switch (roomName) {
      case "Standard Room":
        return 0;
      case "Classic Landscape Room":
        return 1;
      case "Classic Poolside Room":
        return 2;
      case "Twin Room":
        return 3;
      case "Deluxe Room":
        return 4;
      case "Junior Suite":
        return 5;
      case "Executive Suite":
        return 6;
      default:
        break;
    }
  }

  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [room, setRoom] = useState(preSelectRoom());
  const [tel, setTel] = useState("");
  const [guests, setGuests] = useState(1);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(false);
  const [processing, setProcessing] = useState(false);
  const cost = useRef(0);

  const today = new Date().toISOString().split("T")[0];

  const calculateDays = () => {
    const startDate = new Date(checkIn);
    const endDate = new Date(checkOut);

    // Calculate the difference in milliseconds
    const differenceInTime = endDate - startDate;

    // Convert the difference in milliseconds to days
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    return differenceInDays > 0 ? differenceInDays : 1;
  }

  const handleGuests = (value) => {
    if (value > rooms2[room].max_capacity)
      setGuests(rooms2[room].max_capacity);
    else
      setGuests(value);
  };

  const handleGuestsRoom = (value) => {
    if (guests > rooms2[value].max_capacity)
      setGuests(rooms2[value].max_capacity);
    else
      setGuests(guests);
  }

  const roomSelect = (value) => {
    setRoom(value);
    handleGuestsRoom(value);
  }

  const handleSubmit = async () => {
    setProcessing(true);

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phoneRegex = /^\+?[0-9]{10,}\/?[0-9]*$/;

    if (
      checkIn.length === 0 ||
      checkOut.length === 0 ||
      name.length === 0 ||
      email.length === 0 ||
      room.length === 0 ||
      guests.length === 0 ||
      tel.length === 0
    )
      setMessage("Please fill all fields to book.");
    else if (!emailRegex.test(email))
      setMessage("Email address in the wrong format. (eg. johndoe@gmail.com)");
    else if (parseInt(guests) < 1 || parseInt(guests) > 4)
      setMessage("Number of guests must be from 1 to 4.");
    else if (!phoneRegex.test(tel))
      setMessage("Phone number in the wrong format. (eg. 0200000002)");
    else if (tel.length < 10)
      setMessage("Inputted phone number is too short");
    else {
      const response = await handleBooking(name, email, tel, checkIn, checkOut, rooms2[room].name, guests, price.current);
      console.log(response);

      if (response != false) {
        setStatus(true);
        setMessage("Thanks for booking. A customer service representative will reach out soon.");
      }
      else {
        setMessage("Booking failed. Please try again later.");
      }
    }

    setProcessing(false);
  };

  const max_capacity = rooms2[room]?.max_capacity ?? 0;

  const numDays = calculateDays();
  const roomPrice = (rooms2[room]?.price ?? 0) + ((guests - 1) * 30);

  cost.current = (numDays) * (roomPrice);

  return (
    <section className="padding py-[60px] mb-[120px] flex justify-between gap-[2rem] md:flex-row flex-col">
      <section>
        <div className="flex sm:gap-[3rem] gap-[1rem]">
          <h1 className="text-darkBrown sm:text-[39px] text-[25px] mar md:leading-[46px]">
            {roomName}
          </h1>
          <p className="text-orange sm:text-[39px] text-[25px] mar leading-[46px]">
            {price}
          </p>
        </div>

        <div className="mt-[48px] flex md:gap-[40px] gap-[20px] flex-wrap">
          <p className="text-lightBrown tracking-[0.3px] leading-[22px] poppins">
            Non-Refundable
          </p>
          <p className="text-lightBrown tracking-[0.3px] leading-[22px] poppins">
            Bed & Breakfast
          </p>
          <p className="text-lightBrown tracking-[0.3px] leading-[22px] poppins">
            Room capacity <br className="hidden md:flex" />
            {adult_max} Adults, 2 Children
          </p>
          <p className="text-lightBrown tracking-[0.3px] leading-[22px] poppins">
            Room rates are Inclusive of Tax<br className="hidden md:flex" />

          </p>
        </div>
        <div className="my-[50px] h-[1px] w-full bg-[#E7E4DE]"></div>
        <h1 className="text-darkBrown sm:text-[31px] text-[23px] mar leading-[46px] mb-[20px]">
          Services
        </h1>
        <div className="flex gap-[22px] flex-wrap">
          {/* main */}
          <div className="grid grid-cols-4 gap-[22px]">
            {rooms2[room]?.bedding.map((item) => (
              <div className="flex gap-[10px] w-[180px] items-start">
                <img
                  src="/images/service4.svg"
                  alt="icon"
                  className="w-[20px] h-[20px] "
                />
                <p className="text-lightBrown text-sm tracking-[0.3px] poppins leading-[22px]">
                  {item}
                </p>
              </div>
            ))}
          </div>
          <div className="grid grid-cols-4 gap-[22px] mt-4">
            {rooms2[room]?.bathroom.map((item) => (
              <div className="flex gap-[10px] w-[180px] items-start">
                <img
                  src="/images/shower.svg"
                  alt="icon"
                  className="w-[20px] h-[20px] "
                />
                <p className="text-lightBrown text-sm tracking-[0.3px] poppins leading-[22px]">
                  {item}
                </p>
              </div>
            ))}
          </div>
          <div className="grid grid-cols-4 gap-[22px] mt-4">
            {rooms2[room]?.furnishing.map((item) => (
              <div className="flex gap-[10px] w-[180px] items-start">
                <img
                  src="/images/bard.svg"
                  alt="icon"
                  className="w-[20px] h-[20px] "
                />
                <p className="text-lightBrown text-sm tracking-[0.3px] poppins leading-[22px]">
                  {item}
                </p>
              </div>
            ))}
          </div>
          <div className="grid grid-cols-4 gap-[22px] mt-4">
            {rooms2[room]?.features.map((item) => (
              <div className="flex gap-[10px] w-[180px] items-start">
                <img
                  src="/images/home.svg"
                  alt="icon"
                  className="w-[20px] h-[20px] "
                />
                <p className="text-lightBrown text-sm tracking-[0.3px] poppins leading-[22px]">
                  {item}
                </p>
              </div>
            ))}
          </div>
          <div className="grid grid-cols-4 gap-[22px] mt-4">
            {rooms2[room]?.food.map((item) => (
              <div className="flex gap-[10px] w-[180px] items-start">
                <img
                  src="/images/service1.svg"
                  alt="icon"
                  className="w-[20px] h-[20px] "
                />
                <p className="text-lightBrown text-sm tracking-[0.3px] poppins leading-[22px]">
                  {item}
                </p>
              </div>
            ))}
          </div>
          <div className="grid grid-cols-4 gap-[22px] mt-4">
            {rooms2[room]?.phones.map((item) => (
              <div className="flex gap-[10px] w-[180px] items-start">
                <img
                  src="/images/phone.svg"
                  alt="icon"
                  className="w-[20px] h-[20px] "
                />
                <p className="text-lightBrown text-sm tracking-[0.3px] poppins leading-[22px]">
                  {item}
                </p>
              </div>
            ))}
          </div>
          <div className="grid grid-cols-4 gap-[22px] mt-4">
            {rooms2[room]?.entertainment.map((item) => (
              <div className="flex gap-[10px] w-[180px] items-start">
                <img
                  src="/images/service6.svg"
                  alt="icon"
                  className="w-[20px] h-[20px] "
                />
                <p className="text-lightBrown text-sm tracking-[0.3px] poppins leading-[22px]">
                  {item}
                </p>
              </div>
            ))}
          </div>
          <div className="grid grid-cols-4 gap-[22px] mt-4">
            {rooms2[room]?.property.map((item) => (
              <div className="flex gap-[10px] w-[180px] items-start">
                <img
                  src="/images/service3.svg"
                  alt="icon"
                  className="w-[20px] h-[20px] "
                />
                <p className="text-lightBrown text-sm tracking-[0.3px] poppins leading-[22px]">
                  {item}
                </p>
              </div>
            ))}
          </div>

          {/* <div className="w-[180px] flex flex-col gap-[22px]">
            {service1.map((item) => (
              <div className="flex gap-[10px] items-start">
                <img
                  src={item.image}
                  alt="icon"
                  className="w-[20px] h-[20px] "
                />
                <p className="text-lightBrown tracking-[0.3px] poppins leading-[22px]">
                  {item.title}
                </p>
              </div>
            ))}
          </div> */}

          {/* <div className="w-[200px] flex flex-col gap-[22px]">
            {service2.map((item) => (
              <div className="flex gap-[10px] items-start">
                <img
                  src={item.image}
                  alt="icon"
                  className="w-[20px] h-[20px] "
                />
                <p className="text-lightBrown tracking-[0.3px] poppins leading-[22px]">
                  {item.title}
                </p>
              </div>
            ))}
          </div>
          <div className="w-[200px] flex flex-col gap-[22px]">
            {service3.map((item) => (
              <div className="flex gap-[10px] items-start">
                <img
                  src={item.image}
                  alt="icon"
                  className="w-[20px] h-[20px] "
                />
                <p className="text-lightBrown tracking-[0.3px] poppins leading-[22px]">
                  {item.title}
                </p>
              </div>
            ))}
          </div>
          <div className="w-[180px] flex flex-col gap-[22px]">
            {service4.map((item) => (
              <div className="flex gap-[10px] items-start">
                <img
                  src={item.image}
                  alt="icon"
                  className="w-[20px] h-[20px] "
                />
                <p className="text-lightBrown tracking-[0.3px] poppins leading-[22px]">
                  {item.title}
                </p>
              </div>
            ))}
          </div> */}
        </div>

        <div className="my-[50px] h-[1px] w-full bg-[#E7E4DE]"></div>
        <h1 className="text-darkBrown sm:text-[31px] text-[23px] mar leading-[46px] mb-[20px]">
          Hotel Rules
        </h1>

        <div className="flex flex-col gap-[5px]">
          <div className="flex items-center gap-[10px]">
            <div className="bg-lightBrown h-[5px] w-[5px] rounded-full"></div>
            <p className="text-lightBrown tracking-[0.3px] poppins leading-[22px]">
              Check-in: After 2:00PM
            </p>
          </div>
          <div className="flex items-center gap-[10px]">
            <div className="bg-lightBrown h-[5px] w-[5px] rounded-full"></div>
            <p className="text-lightBrown tracking-[0.3px] poppins leading-[22px]">
              Check-out: 12noon
            </p>
          </div>
          <div className="flex items-center gap-[10px]">
            <div className="bg-lightBrown h-[5px] w-[5px] rounded-full"></div>
            <p className="text-lightBrown tracking-[0.3px] poppins leading-[22px]">
              No Smoking
            </p>
          </div>
          <div className="flex items-center gap-[10px]">
            <div className="bg-lightBrown h-[5px] w-[5px] rounded-full"></div>
            <p className="text-lightBrown tracking-[0.3px] poppins leading-[22px]">
              No pets are allowed
            </p>
          </div>
        </div>

        <div className="my-[50px] h-[1px] w-full bg-[#E7E4DE]"></div>
        <h1 className="text-darkBrown sm:text-[31px] text-[23px] mar leading-[46px] mb-[20px]">
          Leave a Comment
        </h1>

        <div className="flex gap-[10px] sm:flex-row flex-col w-full">
          <input
            type="tet"
            placeholder="Your Name*"
            className="border-b border-[#C5BFB1] bg-transparent outline-none py-2  w-full placeholder-[#A69C86] text-[16px] poppins"
          />
          <input
            type="email"
            placeholder="Your Email*"
            className="border-b border-[#C5BFB1] bg-transparent outline-none py-2  w-full placeholder-[#A69C86] text-[16px] poppins"
          />
          <input
            type="text"
            placeholder="Your Message"
            className="border-b border-[#C5BFB1] bg-transparent outline-none py-2  w-full placeholder-[#A69C86] text-[16px] poppins"
          />
        </div>

        <input
          type="text"
          placeholder="Your Email*"
          className="border-b border-[#C5BFB1] bg-transparent outline-none py-2  w-full placeholder-[#A69C86] text-[16px] poppins mt-[40px]"
        />

        <div className="flex items-center mt-[20px] gap-[10px]">
          <input type="checkbox" className="w-[24px] h-[24px]" />
          <p className=" text-[#A69C86] w-full text-[16px] poppins">
            Save my details in the browser for the next time i comment
          </p>
        </div>

        <button className="w-[170px] text-center mt-[60px] border-[#9B8F76] border py-[16px] px-[24px] rounded-[100px] text-[16px] poppins text-[#70603C] leading-[19px]  hover:bg-orange hover:text-white transition hover:border-orange ">
          Send
        </button>
      </section>
      <section className="sm:w-[443px] w-full sm:h-[650px] bg-[#F8F7F5] border border-[#E7E4DE] sm:p-[20px] rounded-[16px]">
        <h1 className="text-darkBrown sm:text-[31px] text-[23px] mar leading-[46px] sm:mb-[10px] sm:p-0 p-2">
          Book this room
        </h1>
        {/* Form */}
        {message.length > 0 && (
          <p className="text-orange w-full text-center pb-[10px]">{message}</p>
        )}
        <section className="flex flex-col gap-[1rem] sm:flex hidden w-full">
          <div className="flex items-center justify-between text-nowrap gap-[10px]">
            <label className="text-[14px] poppins text-deepBrown tracking-[0.3px] leading-[20px]">
              Check In Date*
            </label>
            <input
              type="date"
              name="checkin"
              id=""
              min={today}
              className="border border-[#C5BFB1] rounded-[10px] h-[40px] pl-[1rem] w-[280px]"
              onChange={(e) => setCheckIn(e.target.value)}
            />
          </div>
          <div className="flex items-center justify-between text-nowrap gap-[10px]">
            <label className="text-[14px] poppins text-deepBrown tracking-[0.3px] leading-[20px]">
              Check Out Date*
            </label>
            <input
              type="date"
              name="checkout"
              min={checkIn || today}
              id=""
              className="border border-[#C5BFB1] rounded-[10px] h-[40px] pl-[1rem] w-[280px]"
              onChange={(e) => setCheckOut(e.target.value)}
            />
          </div>
          <div className="flex items-center justify-between text-nowrap gap-[10px]">
            <label className="text-[14px] poppins text-deepBrown tracking-[0.3px] leading-[20px]">
              Full Name*
            </label>
            <input
              type="text"
              name="full name"
              id=""
              className="border border-[#C5BFB1] rounded-[10px] h-[40px] pl-[1rem] sm:w-[280px] w-full"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="flex items-center justify-between text-nowrap gap-[10px]">
            <label className="text-[14px] poppins text-deepBrown tracking-[0.3px] leading-[20px]">
              Email*
            </label>
            <input
              type="email"
              name=""
              id=""
              className="border border-[#C5BFB1] rounded-[10px] h-[40px] pl-[1rem] sm:w-[280px] w-full"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="flex items-center justify-between text-nowrap gap-[10px]">
            <label className="text-[14px] poppins text-deepBrown tracking-[0.3px] leading-[20px]">
              Telephone*
            </label>
            <input
              type="tel"
              name=""
              id=""
              className="border border-[#C5BFB1] rounded-[10px] h-[40px] pl-[1rem] sm:w-[280px] w-full"
              onChange={(e) => setTel(e.target.value)}
            />
          </div>
          <div className="flex items-center  justify-between  gap-[10px]">
            <label className="text-[14px] poppins text-deepBrown tracking-[0.3px] leading-[20px]">
              Select Room Type*
            </label>
            <div>
              <select
                name="Room List"
                id=""
                className="border border-[#C5BFB1] rounded-[10px] h-[40px] pl-[1rem] sm:w-[280px] w-[200px] sm:text-[16px] text-[14px]"
                onChange={(e) => roomSelect(e.target.value)}
              >
                <option value="select a room">Select a room</option>
                {rooms2.map((room, index) => {
                  return (
                    <option key={index} value={index} selected={room.name === roomName}>
                      {room.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="flex items-center justify-between text-nowrap gap-[10px]">
            <label className="text-[14px] poppins text-deepBrown tracking-[0.3px] leading-[20px]">
              Number of Guests*
            </label>
            <input
              type="number"
              name=""
              id=""
              value={guests}
              min={1}
              max={max_capacity}
              className="border border-[#C5BFB1] rounded-[10px] h-[40px] pl-[1rem] w-[280px]"
              onChange={(e) => handleGuests(e.target.value)}
              title={"Max number: " + max_capacity}
            />
          </div>
        </section>
        <p className="text-orange font-bold w-full mt-5">Total cost: ${cost.current}</p>
        <button
          disabled={processing}
          onClick={handleSubmit}
          className="text-center mt-[20px] border-[#9B8F76] border py-[16px] px-[24px] rounded-[100px] text-[16px] poppins text-[#70603C] leading-[19px]  hover:bg-orange hover:text-white transition hover:border-orange sm:flex hidden">
          {processing ? "Booking..." : "Book Now"}
        </button>
        <section className="sm:hidden flex">
          <FormMobile />
        </section>
      </section>
    </section>
  );
};

export default RoomDetailsData;
