export const rooms = [
    "Standard Room - $130",
    "Classic Landscape Room - $140",
    "Classic Poolside Room - $155",
    "Twin Room - $160",
    "Deluxe Room - $170",
    "Junior Suite - $215",
    "Executive Suite - $400"
];

export const rooms2 = [
    {
        "name": "Standard Room",
        "price": 130,
        "max_capacity": 2,
        "bedding": [
            "1 Queen size bed",
            "Cribs allowed: 1",
            "Pillow top, mattress and duvet"
        ],
        "bathroom": [
            "Bath robe & slippers",
            "Toiletries",
            "Hair dryer",
            "Shower/bath"
        ],
        "furnishing": [
            "Writing desk & chair with electric outlets",
            "Couch",
            "Iron and ironing board",
            "Alarm clock",
            "Safe",
            "Wardrobe",
            "Clothes hangers",
            "Luggage rack"
        ],
        "features": [
            "Telephone",
            "Air conditioner",
            "Fan",
            "Internet access",
            "Smart TV",
            "Bluetooth speaker",
            "Fridge"
        ],
        "food": [
            "Room service",
            "Complimentary bottle of water",
            "Coffee, tea and kettle"
        ],
        "phones": [
            "1 Phone (with voicemail)",
            "Wired Internet",
            "Wireless Internet"
        ],
        "entertainment": [
            "Movie channels",
            "Cable/satellite",
            "Smart TV and remote control"
        ],
        "property": [
            "Swimming pool",
            "Gymnasium",
            "Lounge and bar",
            "Restaurant",
            "Complementary parking space",
            "Luggage holding room"
        ]
    },
    {
        "name": "Classic Landscape Room",
        "price": 140,
        "max_capacity": 2,
        "bedding": [
            "1 Queen size bed",
            "Cribs allowed: 1",
            "Pillow top, mattress and duvet"
        ],
        "bathroom": [
            "Bath robe & slippers",
            "Toiletries",
            "Hair dryer",
            "Shower/bath"
        ],
        "furnishing": [
            "Writing desk & chair with electric outlets",
            "Couch",
            "Iron and ironing board",
            "Alarm clock",
            "Safe",
            "Wardrobe",
            "Clothes hangers",
            "Luggage rack"
        ],
        "features": [
            "Telephone",
            "Air conditioner",
            "Fan",
            "Internet access",
            "Smart TV",
            "Bluetooth speaker",
            "Fridge"
        ],
        "food": [
            "Room service",
            "Complimentary bottle of water",
            "Coffee, tea and kettle"
        ],
        "phones": [
            "1 Phone (with voicemail)",
            "Wired Internet",
            "Wireless Internet"
        ],
        "entertainment": [
            "Movie channels",
            "Cable/satellite",
            "Smart TV and remote control"
        ],
        "property": [
            "Swimming pool",
            "Gymnasium",
            "Lounge and bar",
            "Restaurant",
            "Complementary parking space",
            "Luggage holding room"
        ]
    },
    {
        "name": "Classic Poolside Room",
        "price": 155,
        "max_capacity": 2,
        "bedding": [
            "1 King size bed",
            "Cribs allowed: 1",
            "Pillow top, mattress and duvet"
        ],
        "bathroom": [
            "Bath robe & slippers",
            "Toiletries",
            "Hair dryer",
            "Shower/bath"
        ],
        "furnishing": [
            "Dresser",
            "Writing desk & chair with electric outlets",
            "Couch",
            "Iron and ironing board",
            "Alarm clock",
            "Safe",
            "Wardrobe",
            "Clothes hangers",
            "Luggage rack",
        ],
        "features": [
            "Balcony",
            "Telephone",
            "Air conditioner",
            "Fan",
            "Internet access",
            "Smart TV",
            "Bluetooth speaker",
            "Fridge"
        ],
        "food": [
            "Room service",
            "Complimentary bottle of water",
            "Coffee, tea and kettle"
        ],
        "phones": [
            "1 Phone (with voicemail)",
            "Wired Internet",
            "Wireless Internet"
        ],
        "entertainment": [
            "Movie channels",
            "Cable/satellite",
            "Smart TV and remote control"
        ],
        "property": [
            "Swimming pool",
            "Gymnasium",
            "Lounge and bar",
            "Restaurant",
            "Complementary parking space",
            "Luggage holding room"
        ]
    },
    {
        "name": "Twin Room",
        "price": 160,
        "max_capacity": 4,
        "bedding": [
            "2 Double bed",
            "Cribs allowed: 1",
            "Pillow top, mattress and duvet"
        ],
        "bathroom": [
            "Bath robe & slippers",
            "Toiletries",
            "Hair dryer",
            "Shower/bath"
        ],
        "furnishing": [
            "Writing desk & chair with electric outlets",
            "Couch",
            "Iron and ironing board",
            "Alarm clock",
            "Safe",
            "Wardrobe",
            "Clothes hangers",
            "Luggage rack"
        ],
        "features": [
            "Telephone",
            "Air conditioner",
            "Fan",
            "Internet access",
            "Smart TV",
            "Bluetooth speaker",
            "Fridge"
        ],
        "food": [
            "Room service",
            "Complimentary bottle of water",
            "Coffee, tea and kettle"
        ],
        "phones": [
            "1 Phone (with voicemail)",
            "Wired Internet",
            "Wireless Internet"
        ],
        "entertainment": [
            "Movie channels",
            "Cable/satellite",
            "Smart TV and remote control"
        ],
        "property": [
            "Swimming pool",
            "Gymnasium",
            "Lounge and bar",
            "Restaurant",
            "Complementary parking space",
            "Luggage holding room"
        ]
    },
    {
        "name": "Deluxe Room",
        "price": 170,
        "max_capacity": 2,
        "bedding": [
            "1 King size bed",
            "Cribs allowed: 1",
            "Pillow top, mattress and duvet"
        ],
        "bathroom": [
            "Bath robe & slippers",
            "Toiletries",
            "Hair dryer",
            "Shower/bath"
        ],
        "furnishing": [
            "Dresser",
            "Writing desk & chair with electric outlets",
            "Couch",
            "Iron and ironing board",
            "Alarm clock",
            "Safe",
            "Wardrobe",
            "Clothes hangers",
            "Luggage rack",
        ],
        "features": [
            "Balcony",
            "Living room space",
            "Telephone",
            "Air conditioner",
            "Internet access",
            "Smart TV",
            "Fridge"
        ],
        "food": [
            "Room service",
            "Complimentary bottle of water",
            "Coffee, tea and kettle"
        ],
        "phones": [
            "1 Phone (with voicemail)",
            "Wired Internet",
            "Wireless Internet"
        ],
        "entertainment": [
            "Movie channels",
            "Cable/satellite",
            "Smart TV and remote control"
        ],
        "property": [
            "Swimming pool",
            "Gymnasium",
            "Lounge and bar",
            "Restaurant",
            "Complementary parking space",
            "Luggage holding room"
        ]
    },
    {
        "name": "Junior Suite",
        "price": 215,
        "max_capacity": 2,
        "bedding": [
            "1 King size bed",
            "1 Queen size bed",
            "Cribs allowed: 2",
            "Pillow top, mattress and duvet"
        ],
        "bathroom": [
            "Bath robe & slippers",
            "Toiletries",
            "Hair dryer",
            "Shower/bath"
        ],
        "furnishing": [
            "Dresser",
            "Writing desk & chair with electric outlets",
            "Couch",
            "Iron and ironing board",
            "Alarm clock",
            "Safe",
            "Wardrobe",
            "Clothes hangers",
            "Luggage rack",
        ],
        "features": [
            "Balcony",
            "Living room space",
            "Telephone",
            "Air conditioner",
            "Internet access",
            "Smart TV",
            "Fridge"
        ],
        "food": [
            "Room service",
            "Complimentary bottle of water",
            "Coffee, tea and kettle"
        ],
        "phones": [
            "1 Phone (with voicemail)",
            "Wired Internet",
            "Wireless Internet"
        ],
        "entertainment": [
            "Movie channels",
            "Cable/satellite",
            "Smart TV and remote control"
        ],
        "property": [
            "Swimming pool",
            "Gymnasium",
            "Lounge and bar",
            "Restaurant",
            "Complementary parking space",
            "Luggage holding room"
        ]
    },
    {
        "name": "Executive Suite",
        "price": 400,
        "max_capacity": 4,
        "bedding": [
            "1 King size bed",
            "1 Queen size bed",
            "Cribs allowed: 2",
            "Pillow top, mattress and duvet"
        ],
        "bathroom": [
            "Bath robe & slippers",
            "Toiletries",
            "Hair dryer",
            "Shower/bath"
        ],
        "furnishing": [
            "Dresser",
            "Writing desk & chair with electric outlets",
            "Couch",
            "Iron and ironing board",
            "Alarm clock",
            "Safe",
            "Wardrobe",
            "Clothes hangers",
            "Luggage rack",
        ],
        "features": [
            "Balcony",
            "Living room space",
            "Telephone",
            "Air conditioner",
            "Internet access",
            "Smart TV",
            "Fridge"
        ],
        "food": [
            "Room service",
            "Complimentary bottle of water",
            "Coffee, tea and kettle"
        ],
        "phones": [
            "1 Phone (with voicemail)",
            "Wired Internet",
            "Wireless Internet"
        ],
        "entertainment": [
            "Movie channels",
            "Cable/satellite",
            "Smart TV and remote control"
        ],
        "property": [
            "Swimming pool",
            "Gymnasium",
            "Lounge and bar",
            "Restaurant",
            "Complementary parking space",
            "Luggage holding room"
        ]
    }
]