import React from "react";
import { Link } from "react-router-dom";
import { ConferenceRoomData } from "../constants/data";

const Events = () => {
  return (
    <section>
      <section className="padding text-center">
        <p className="sm:mt-[50px] mt-[30px] poppins sm:text-[16px] text-[14px] leading-[19px] text-deepBrown font-normal uppercase">
          Conference room
        </p>

        <h1 className="sm:mt-[20px] mt-[10px] sm:text-[49px] text-[35px] text-orange mar font-normal sm:leading-[58px] leading-[40px]">
          Reserve Your Spot Today - Ignite <br className="hidden md:flex" />
          Extraordinary Experiences!
        </h1>
      </section>

      <section className="flex overflow-hidden slider mt-[100px]">
        <img
          src="/images/event1.png"
          alt="gallery-slide"
          className="w-[432px] h-[461px] object-cover"
        />
        <img
          src="/images/event2.png"
          alt="gallery-slide"
          className="w-[432px] h-[461px] object-cover"
        />
        <img
          src="/images/event3.png"
          alt="gallery-slide"
          className="w-[432px] h-[461px] object-cover"
        />
        <img
          src="/images/event4.png"
          alt="gallery-slide"
          className="w-[432px] h-[461px] object-cover"
        />
        <img
          src="/images/event1.png"
          alt="gallery-slide"
          className="w-[432px] h-[461px] object-cover"
        />
        <img
          src="/images/event2.png"
          alt="gallery-slide"
          className="w-[432px] h-[461px] object-cover"
        />
        <img
          src="/images/event3.png"
          alt="gallery-slide"
          className="w-[432px] h-[461px] object-cover"
        />
        <img
          src="/images/event4.png"
          alt="gallery-slide"
          className="w-[432px] h-[461px] object-cover"
        />
      </section>

      <p className="py-[40px] text-center text-[16px] text-[#000] underline hover:opacity-[1] hover:text-orange transition opacity-[0.6] poppins">
        <Link to={"/gallery"}>View more</Link>
      </p>

      <section className="padding bg-light pb-[70px]">
        <p className="pt-[78px] mb-[20px] uppercase text-lightBrown_100 sm:text-[16px] text-[14px] leading-[19px] poppins">
          Features - conference room
        </p>
        <h2 className="text-deepBrown_100 sm:text-[49px] text-[35px] mar sm:leading-[58px]  leading-[35px] font-normal sm:mb-[60px] mb-[30px]">
          Why choose Crismon Hotel’s
          <br className="md:flex hidden" /> Conference Room?
        </h2>

        <section className="flex overflow-auto w-full flex-wrap ">
          {ConferenceRoomData.map((data) => (
            <div className="pb-[100px] sm:mr-[52px] mr-[25px]">
              <div className="sm:w-[380px] w-full">
                <img
                  src={data.icon}
                  alt="icon"
                  className="sm:mb-[20px] mb-[10px]  w-[38px] h-[37px] object-contain"
                />
                <div className="w-full h-[1px] bg-[#D1CBC0] sm:mb-[18px] mb-[10px]"></div>
                <h2 className="mb-[13px] text-darkBrown sm:text-[25px] text-[20px] poppins leading-[30px] font-normal">
                  {data.title}
                </h2>
                <p className="sm:mb-[18px] mb-[15px] text-darkBrown sm:text-[16px] text-[15px] poppins tracking-[0.3px] leading-[22px] opacity-[0.6]">
                  {data.content}
                </p>
                <div className="w-full h-[1px] bg-[#D1CBC0]"></div>
              </div>
            </div>
          ))}
        </section>
      </section>
    </section>
  );
};

export default Events;
