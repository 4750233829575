export const servicesData = [
  {
    id: 1,
    icon: "/images/service1.svg",
    title: "Booking",
    content: "Hassel free Reservation: Book your stay with confidence",
  },
  {
    id: 2,
    icon: "/images/service2.svg",
    title: "Staff",
    content:
      "Your Comfort is Our Priority: Meet Our Dedicated Staff at Crismon",
  },
  {
    id: 3,
    icon: "/images/service3.svg",
    title: "Serenity",
    content:
      "Serenity and Scenic Views: Discover the Peaceful Beauty of Crismon's Location",
  },
  {
    id: 4,
    icon: "/images/service4.svg",
    title: "Orthopedic Beds",
    content:
      "Sleep Well in Our Orthopedic Beds: Experience a Restful Night's Sleep at Crismon",
  },
  {
    id: 5,
    icon: "/images/service5.svg",
    title: "24/7 Services",
    content:
      "Your Home Away From Home, Always: Discover the comfort of our 24-hour Amenities at Crismon",
  },
  {
    id: 6,
    icon: "/images/service6.svg",
    title: "TV & Cinema",
    content:
      "Entertaiment at Your Fingertips: Enjpy a variety  of channels and movies at Crismon",
  },
];

export const TestimonialsData = [
  {
    id: 1,
    name: "Raphael Bernabeu",
    title: "Review from Ghana Cement",
    content:
      "“ Crismon Hotel was exceptionally clean; the staff were very friendly and helpful; and it was extremely relaxing. We really enjoyed our stay. For a simple, no frills holiday, I don’t think you can beat Crismon Hotel. I would certainly recommend the hotel for people on a limited budget and who don’t want luxury and pampering, but want a most enjoyable stay in the Seychelles. Thank you Crismon Hotel! “",
    link: "iii",
  },

  {
    id: 2,
    name: "George Jnr",
    title: "Digitalwebhost.com",
    content:
      "“ The staff were incredibly welcoming and went above and beyond to ensure that my stay was comfortable and enjoyable. The rooms and suit are exquisitely furnished and provided thwe perfect balance of luxuury and comfort. The state-of-the-art facilities and aminities surpassed my expectations, and i left feeling completely relaxed and rejuvinated. I can not recommend Crismon Hotel enough - it truly is a home from home  “",
    linkR: "uuu",
  },

  {
    id: 3,
    name: "Mr. Caleb Fleming",
    title: "Review from Traveller",
    content:
      "“ Amazing place, I recently spent 2 weeks at Crismon Hotel. I can honestly say that i enjoyed every minute. Firstly i ate all my meals at the hotel, and was never dissapointed, I thoroughly enjoyed every meal. The staff were very friendly and helpful. The owners of La Rousette are warm, caring and very accommodating people. My room was clean and cosey, i slept very well. I will be back this year and definitely plan to spend more time at Crismon Hotel. I really loved the kindness and hospitality that was offered  “",
  },
];

export const RecentNewsData = [
  {
    id: 1,
    heading: "Luxury Hotel",
    title: "Luxury at Crismon Hotel",
    content:
      "Luxurious Rooms and Suites At Crismon Hotel, we take pride in offering spacious, exquisitely furnished rooms and suites that...",
    image: "/images/news1.png",
  },
  {
    id: 2,
    heading: "Luxury Hotel",
    title: "Why Choose Crismon Hotel",
    content:
      "When it comes to indulging in unparalleled luxury and experiencing exceptional hospitality, there's no better choice than Crism...",
    image: "/images/news2.png",
  },
  {
    id: 3,
    heading: "FAQ, Luxury Hotel",
    title: "FAQ about Crismon Hotel",
    content:
      "Welcome to Crismon Hotel, where luxury and elegance meet to create an unforgettable experience. We understand that you may have...",
    image: "/images/news3.png",
  },
];

export const bookingData = [
  {
    id: 1,
    title: "Standard Room",
    price: "$130",
    image: "/images/room6.jpeg",
    path: "/rooms&suits/room-details-standard-room",
  },
  {
    id: 2,
    title: "Classic Landscape Room",
    price: "$140",
    image: "/images/room1.jpg",
    path: "/rooms&suits/room-details-classic-landscape-room",
  },
  {
    id: 3,
    title: "Classic Poolside Room",
    price: "$155",
    image: "/images/room5.jpeg",
    path: "/rooms&suits/room-details-classic-poolside-room",
  },
  {
    id: 4,
    title: "Twin Room",
    price: "$160",
    image: "/images/room4.jpg",
    path: "/rooms&suits/room-details-twin-room",
  },
  {
    id: 5,
    title: "Deluxe Room",
    price: "$170",
    image: "/images/room3.jpg",
    path: "/rooms&suits/room-details-deluxe-room",
  },
  {
    id: 6,
    title: "Junior Suite",
    price: " $215",
    image: "/images/room2.jpg",
    path: "/rooms&suits/room-details-junior-suite",
  },
  {
    id: 7,
    title: "Executive Suite",
    price: "$400",
    image: "/images/room7.jpg",
    path: "/rooms&suits/room-details-executive-suite",
  },
];

export const ConferenceRoomData = [
  {
    id: 1,
    icon: "/images/room1.svg",
    title: "Empowering Technology",
    content:
      "Harness the power of cutting-edge technology with our state-of-the-art audiovisual systems, high-speed internet, and advanced presentation tools. Seamlessly connect with your audience and create immersive experiences that engage and inspire.",
  },
  {
    id: 2,
    icon: "/images/room2.svg",
    title: "Flexibility Redefined",
    content:
      "Tailor the space to your specific needs with our versatile layout options. Whether it’s a small and intimate gathering or a grand corporate event, our conference room can be customized to accommodate your vision and goals.",
  },
  {
    id: 3,
    icon: "/images/room3.svg",
    title: "Unrivaled Amenities",
    content:
      "Experience the utmost in hospitality with our array of exceptional amenities. From dedicated event staff providing personalized service to exquisite catering options, we ensure every detail is taken care of, allowing you to focus on what matters most – your event’s success.",
  },
];

export const BreakfastData = [
  {
    id: 1,
    tags: "#pancake #breakfastindulgence  #caramelizedbanans",
    title: "Caramelized Banana Pancake Cake",
    price: "$104.06",
    image: "/images/menu.png",
  },
  {
    id: 2,
    tags: "#breakfastomelet",
    title: "Breakfast Omelet",
    price: "$104.06",
    image: "/images/menu.png",
  },
  {
    id: 3,
    tags: "#pancake #breakfastindulgence  #caramelizedbanans",
    title: "Breakfast Wraps",
    price: "$104.06",
    image: "/images/menu.png",
  },
];

export const LaunchData = [
  {
    id: 1,
    tags: "#pancake #breakfastindulgence  #caramelizedbanans",
    title: "Caramelized Banana Pancake Cake",
    price: "$104.06",
    image: "/images/menu.png",
  },
  {
    id: 2,
    tags: "#breakfastomelet",
    title: "Breakfast Omelet",
    price: "$104.06",
    image: "/images/menu.png",
  },
  {
    id: 3,
    tags: "#pancake #breakfastindulgence  #caramelizedbanans",
    title: "Breakfast Wraps",
    price: "$104.06",
    image: "/images/menu.png",
  },
  {
    id: 4,
    tags: "#pancake #breakfastindulgence  #caramelizedbanans",
    title: "Breakfast Wraps",
    price: "$104.06",
    image: "/images/menu.png",
  },
];

export const DinnerData = [
  {
    id: 1,
    tags: "#pancake #breakfastindulgence  #caramelizedbanans",
    title: "Caramelized Banana Pancake Cake",
    price: "$104.06",
    image: "/images/menu.png",
  },
  {
    id: 2,
    tags: "#breakfastomelet",
    title: "Breakfast Omelet",
    price: "$104.06",
    image: "/images/menu.png",
  },
  {
    id: 3,
    tags: "#pancake #breakfastindulgence  #caramelizedbanans",
    title: "Breakfast Wraps",
    price: "$104.06",
    image: "/images/menu.png",
  },
  {
    id: 4,
    tags: "#pancake #breakfastindulgence  #caramelizedbanans",
    title: "Breakfast Wraps",
    price: "$104.06",
    image: "/images/menu.png",
  },
  {
    id: 5,
    tags: "#pancake #breakfastindulgence  #caramelizedbanans",
    title: "Breakfast Wraps",
    price: "$104.06",
    image: "/images/menu.png",
  },
];

export const DesertData = [
  {
    id: 1,
    tags: "#pancake #breakfastindulgence  #caramelizedbanans",
    title: "Caramelized Banana Pancake Cake",
    price: "$104.06",
    image: "/images/menu.png",
  },
  {
    id: 2,
    tags: "#breakfastomelet",
    title: "Breakfast Omelet",
    price: "$104.06",
    image: "/images/menu.png",
  },
  {
    id: 3,
    tags: "#pancake #breakfastindulgence  #caramelizedbanans",
    title: "Breakfast Wraps",
    price: "$104.06",
    image: "/images/menu.png",
  },
  {
    id: 4,
    tags: "#pancake #breakfastindulgence  #caramelizedbanans",
    title: "Breakfast Wraps",
    price: "$104.06",
    image: "/images/menu.png",
  },
  {
    id: 5,
    tags: "#pancake #breakfastindulgence  #caramelizedbanans",
    title: "Breakfast Wraps",
    price: "$104.06",
    image: "/images/menu.png",
  },
  {
    id: 6,
    tags: "#pancake #breakfastindulgence  #caramelizedbanans",
    title: "Breakfast Wraps",
    price: "$104.06",
    image: "/images/menu.png",
  },
];

export const service1 = [
  { id: 1, title: "King Size Bed", image: "/images/bard.svg" },
  { id: 2, title: "Hairdryer", image: "/images/bard.svg" },
  { id: 3, title: "Luxury Bathroom Amenities", image: "/images/bard.svg" },
  { id: 4, title: "Tea & Coffee Facilities", image: "/images/bard.svg" },
];

export const service2 = [
  { id: 1, title: "2x Televisions", image: "/images/bard.svg" },
  { id: 2, title: "Highspeed Internet", image: "/images/bard.svg" },
  { id: 3, title: "Mini Refrigerator", image: "/images/bard.svg" },
  { id: 4, title: "Air Conditioner", image: "/images/bard.svg" },
];

export const service3 = [
  { id: 1, title: "In-room Safe", image: "/images/safe.svg" },
  { id: 2, title: "Robe & Slippers", image: "/images/bard.svg" },
  { id: 3, title: "Gym & Pool access", image: "/images/bard.svg" },
  { id: 4, title: "Iron & Ironing Board", image: "/images/bard.svg" },
];

export const service4 = [{ id: 1, title: "Shower", image: "/images/bard.svg" }];
