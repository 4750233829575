import React from "react";
import Navbar from "../components/Navbar";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";

const Terms = () => {
  return (
    <section>
      <Navbar pageTitle={"Terms & Conditions"} />
      <Header pageTitle={"Terms & Conditions"} classTitle={"about-bg"} />

      <section className="padding mt-[4rem]">
        <p className="text-[16px] poppins text-darkBrown leading-[22px]">
          <span className="font-semibold">Terms and Conditions </span>
          Please read these terms and conditions carefully before using the
          services of Crismon Hotel. By making a reservation, accessing our
          website, or using our services, you agree to comply with and be bound
          by these terms and conditions.
        </p>
        <p className="text-[16px] poppins text-darkBrown leading-[22px] mt-[2rem]">
          <span className="font-semibold">1. Reservations: </span>
          1.1. All reservations are subject to availability and confirmation.
          1.2. Guests must provide accurate and complete information during the
          reservation process. 1.3. Any changes or modifications to reservations
          may be subject to additional charges and availability.
        </p>

        <p className="text-[16px] poppins text-darkBrown leading-[22px] mt-[2rem]">
          <span className="font-semibold">2. Check-in and Check-out: </span>
          2.1. Check-in time is [insert time], and check-out time is [insert
          time]. Early check-in or late check-out may be subject to additional
          charges and availability.
        </p>
        <p className="text-[16px] poppins text-darkBrown leading-[22px] mt-[2rem]">
          <span className="font-semibold">3. Payment and Cancellation: </span>
          3.1. Payment terms and cancellation policies vary depending on the
          type of reservation and rate selected. Please refer to the specific
          terms provided during the reservation process. 3.2. Failure to comply
          with the payment or cancellation policies may result in the
          reservation being canceled or modified.
        </p>

        <p className="text-[16px] poppins text-darkBrown leading-[22px] mt-[2rem]">
          <span className="font-semibold">4. Guest Responsibilities: </span>
          4.1. Guests are responsible for any damage or loss caused to the hotel
          property during their stay and may be charged for the cost of repair
          or replacement. 4.2. Guests must comply with all hotel policies,
          including those regarding noise, smoking, pets, and conduct. Failure
          to comply may result in eviction without refund.
        </p>

        <p className="text-[16px] poppins text-darkBrown leading-[22px] mt-[2rem]">
          <span className="font-semibold">5. Limitation of Liability: </span>
          5.1. Crismon Hotel shall not be liable for any loss, damage, or
          injury, whether direct, indirect, incidental, or consequential,
          arising from the use or inability to use our services, except where
          prohibited by law.
        </p>

        <p className="text-[16px] poppins text-darkBrown leading-[22px] mt-[2rem]">
          <span className="font-semibold">6. Privacy: </span>
          6.1. The collection and use of personal information by Crismon Hotel
          are governed by our Privacy Policy. By using our services, you consent
          to the collection and use of your personal information as described in
          the Privacy Policy.
        </p>

        <p className="text-[16px] poppins text-darkBrown leading-[22px] mt-[2rem]">
          <span className="font-semibold">7. Intellectual Property: </span>
          7.1. All intellectual property rights, including trademarks, logos,
          and content, displayed on our website or in our services, are the
          property of Crismon Hotel or their respective owners. Unauthorized use
          is strictly prohibited.
        </p>

        <p className="text-[16px] poppins text-darkBrown leading-[22px] mt-[2rem]">
          <span className="font-semibold">8. Force Majeure: </span>
          8.1. Crismon Hotel shall not be liable for any failure or delay in
          performing its obligations under these terms and conditions due to
          circumstances beyond its control, including but not limited to acts of
          nature, government restrictions, or labor disputes.
        </p>

        <p className="text-[16px] poppins text-darkBrown leading-[22px] mt-[2rem]">
          <span className="font-semibold">
            9. Governing Law and Jurisdiction:{" "}
          </span>
          9.1. These terms and conditions shall be governed by and construed in
          accordance with the laws of [insert jurisdiction]. Any disputes
          arising from these terms and conditions shall be subject to the
          exclusive jurisdiction of the courts of [insert jurisdiction].
        </p>
        <p className="text-[16px] poppins text-darkBrown leading-[22px] mt-[2rem]">
          <span className="font-semibold">10. Modifications: </span>
          10.1. Crismon Hotel reserves the right to modify or update these terms
          and conditions at any time. The updated version will be posted on our
          website. Continued use of our services after any modifications
          constitutes acceptance of the updated terms and conditions.
        </p>
        <p className="text-[16px] poppins text-darkBrown leading-[22px] mt-[2rem]">
          <span className="font-semibold">11. Contact Us: </span>
          11.1. If you have any questions, concerns, or requests regarding these
          terms and conditions, please contact us at{" "}
          <Link to={"/"} className="text-orange hover:underline">
            https ://www.crismonhotel.com/contact
          </Link>
        </p>
      </section>
      <div className="mt-[5rem]"></div>
      <Footer />
    </section>
  );
};

export default Terms;
