import React from "react";
import Navbar from "../components/Navbar";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import Header from "../components/Header";
import News from "../components/News";
import { Link } from "react-router-dom";
import { RecentNewsData } from "../constants/data";

const Blog = () => {
  return (
    <div>
      <Navbar pageTitle={"Blog"} />
      <Header pageTitle={"Blog"} classTitle={"blog-bg"} />
      <section className="py-[70px] padding">
        <div className="flex gap-[42px] relative items-end flex-wrap">
          <input
            type="text"
            placeholder="Search"
            className="border-b border-[#C5BFB1] sm:w-[400px] w-full sm:pl-[2rem] text-[17px] poppins outline-none pb-[10px]"
          />
          <button className="border border-[#9B8F76] px-[20px] py-[14px] rounded-[100px] text-[16px] text-[#70603C] poppins hover:bg-[#9B8F76] hover:text-white transition">
            Search
          </button>
          <img
            src="/images/search-icon.svg"
            alt="search-icon"
            className="absolute w-[24px] h-[24px] object-contain top-[1.2rem] sm:flex hidden"
          />
        </div>
      </section>
      <section className="scrollbar1 flex gap-[24px] overflow-auto pb-[60px] padding">
        {RecentNewsData.map((data) => (
          <div key={data.id}>
            <div className="w-[400px]">
              <img
                src={data.image}
                alt="news_image"
                className="h-[366px] object-cover"
              />

              <div className="p-[10px]">
                <p className="opacity-[0.6] text-[#000] poppins text-[16px] leading-[22px] tracking-[0.3px]">
                  {data.heading}
                </p>

                <h2 className="mt-[9px] text-[#000] text-[22px] poppins leading-[30px] mb-[16px]">
                  {data.title}
                </h2>

                <p className="text-lightBrown text-[16px] leading-[22px] tracking-[0.3px] poppins mb-[13px]">
                  {data.content}
                </p>
                <Link
                  to={`/news/${data.id}`}
                  className="opacity-[0.6] text-[#000] text-[16px] poppins underline leading-[25px] tracking-[0.3px]"
                >
                  Read more
                </Link>
              </div>
            </div>
          </div>
        ))}
      </section>
      <Newsletter />
      <Footer />
    </div>
  );
};

export default Blog;
