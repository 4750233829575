import React from "react";
import { Link } from "react-router-dom";

const NavMenu = ({ setToggleMenu }) => {
  return (
    <section className="fixed top-0 left-0 w-full overflow-auto nav-menu h-full ">
      <section className="bg-light w-full ">
        <nav className="xl:px-[60px] px-[30px] xl:pt-[34px] pt-[20px] grid sm:grid-cols-3 grid-cols-2 justify-between items-center nav-b w-full">
          <img
            src="/images/close.svg"
            alt="close-icon"
            className="sm:w-[40px] w-[30px] cursor-pointer"
            onClick={() => setToggleMenu(false)}
          />
          <Link to="/" className="flex sm:justify-center justify-end">
            <img
              src="/images/logo-orange.svg"
              alt="logo"
              className="w-[85px] h-[77px] object-contain "
              onClick={() => setToggleMenu(false)}
            />
          </Link>

          <Link to="/booking" className="sm:flex hidden justify-end">
            <button className="sm:px-[22px] px-[20px] sm:py-[12px] py-[10px] text-[18px] poppins leading-[24px] font-normal text-white rounded-[100px] bg-darkBrown  text-center hover:bg-orange hover:text-white transition">
              Book Now
            </button>
          </Link>
        </nav>

        <section className="xl:px-[119px] lg:px-[60px] px-[30px] xl:py-[30px] py-[30px] mt-[20px] flex items-end justify-center flex-col md:flex-row gap-[3rem] md:gap-[0rem]">
          <section className="w-full md:border-r xl:pr-[103px] lg:pr-[60px] pr-[30px]">
            <div className="flex flex-col gap-[39px]">
              <Link
                to={"/about"}
                className="cursor-pointer transition flex items-end text-nowrap gap-[18px] nav-link"
              >
                <h1 className="lg:text-[39px] sm:text-[30px] text-[25px] mar font-normal leading-[46px] text-darkBrown hover:text-orange ">
                  About Us
                </h1>
                <div className="h-[1px] w-full bg-[#C5BFB1] hidden sm:flex"></div>
                <p className="text-[16px] mar leading-[18px] text-darkBrown nav-link-num hidden sm:flex">
                  01
                </p>
              </Link>

              <Link
                to={"/booking"}
                className="cursor-pointer hover:text-orange transition flex items-end text-nowrap gap-[18px] nav-link"
              >
                <h1 className="lg:text-[39px] sm:text-[30px] text-[25px] mar font-normal leading-[46px] text-darkBrown hover:text-orange">
                  Booking
                </h1>
                <div className="h-[1px] w-full bg-[#C5BFB1] hidden sm:flex"></div>
                <p className="text-[16px] mar leading-[18px] text-darkBrown nav-link-num hidden sm:flex">
                  02
                </p>
              </Link>

              <Link
                to={"/meetings&events"}
                className="cursor-pointer hover:text-orange transition flex items-end text-nowrap gap-[18px] nav-link"
              >
                <h1 className="lg:text-[39px] sm:text-[30px] text-[25px] mar font-normal leading-[46px] text-darkBrown hover:text-orange">
                  Meetings & Events
                </h1>
                <div className="h-[1px] w-full bg-[#C5BFB1] hidden sm:flex"></div>
                <p className="text-[16px] mar leading-[18px] text-darkBrown nav-link-num hidden sm:flex">
                  03
                </p>
              </Link>
              <Link
                to={"/restaurant"}
                className="cursor-pointer hover:text-orange transition flex items-end text-nowrap gap-[18px] nav-link"
              >
                <h1 className="lg:text-[39px] sm:text-[30px] text-[25px] mar font-normal leading-[46px] text-darkBrown hover:text-orange">
                  Restaurant
                </h1>
                <div className="h-[1px] w-full bg-[#C5BFB1] hidden sm:flex"></div>
                <p className="text-[16px] mar leading-[18px] text-darkBrown nav-link-num hidden sm:flex">
                  04
                </p>
              </Link>
              <Link
                to={"/blog"}
                className="cursor-pointer hover:text-orange transition flex items-end text-nowrap gap-[18px] nav-link"
              >
                <h1 className="lg:text-[39px] sm:text-[30px] text-[25px] mar font-normal leading-[46px] text-darkBrown hover:text-orange">
                  Blog
                </h1>
                <div className="h-[1px] w-full bg-[#C5BFB1] hidden sm:flex"></div>
                <p className="text-[16px] mar leading-[18px] text-darkBrown nav-link-num hidden sm:flex">
                  05
                </p>
              </Link>
              <Link
                to={"/gallery"}
                className="cursor-pointer hover:text-orange transition flex items-end text-nowrap gap-[18px] nav-link"
              >
                <h1 className="lg:text-[39px] sm:text-[30px] text-[25px] mar font-normal leading-[46px] text-darkBrown hover:text-orange">
                  Gallery
                </h1>
                <div className="h-[1px] w-full bg-[#C5BFB1] hidden sm:flex"></div>
                <p className="text-[16px] mar leading-[18px] text-darkBrown nav-link-num hidden sm:flex">
                  06
                </p>
              </Link>
              <Link
                to={"/contact-us"}
                className="cursor-pointer hover:text-orange transition flex items-end text-nowrap gap-[18px] nav-link"
              >
                <h1 className="lg:text-[39px] sm:text-[30px] text-[25px] mar font-normal leading-[46px] text-darkBrown hover:text-orange">
                  Contact
                </h1>
                <div className="h-[1px] w-full bg-[#C5BFB1] hidden sm:flex"></div>
                <p className="text-[16px] mar leading-[18px] text-darkBrown nav-link-num hidden sm:flex">
                  07
                </p>
              </Link>
            </div>
          </section>
          <section className="flex md:justify-between gap-[35px] w-full xl:pl-[103px] lg:pl-[60px] md:pl-[30px] flex-wrap">
            <div className="flex flex-col items-start ">
              <div>
                <h2 className="text-darkBrown text-[20px] font-semibold leading-[32px] poppins tracking-[0.3px] mb-[8px]">
                  Contact info
                </h2>
                <p className="text-[16px] poppins text-[#4F4F4F]tracking-[0.3px] leading-[22px]font-normal">
                  (+233) 56 163 3542
                </p>
              </div>
              <div className="mt-[57px]">
                <h2 className="text-darkBrown text-[20px] font-semibold leading-[32px] poppins tracking-[0.3px] mb-[8px]">
                  Office Numbers
                </h2>
                <p className="text-[16px] poppins text-[#4F4F4F]tracking-[0.3px] leading-[22px]font-normal">
                  (+233) 30 320 5547 <br />
                  (+233) 30 320 5680 <br />
                  (+233) 30 321 1942
                </p>
              </div>
            </div>

            <div className="flex flex-col items-start">
              <div>
                <h2 className="text-darkBrown text-[20px] font-semibold leading-[32px] poppins tracking-[0.3px] mb-[8px]">
                  Booking/Reservations
                </h2>
                <p className="text-[16px] poppins text-[#4F4F4F]tracking-[0.3px] leading-[22px]font-normal">
                  info@crismonhotel.com
                  <br /> reservations@crismonhotel.com
                </p>
              </div>
              <div className="mt-[31px]">
                <h2 className="text-darkBrown text-[20px] font-semibold leading-[32px] poppins tracking-[0.3px] mb-[8px]">
                  Departments
                </h2>
                <p className="text-[16px] poppins text-[#4F4F4F]tracking-[0.3px] leading-[22px]font-normal">
                  afedzi@crismonhotel.com <br />
                  accounts@crismonhotel.com <br />
                  baaban@crismonhotel.com <br />
                  aban@crismonhotel.com <br />
                  keith-alvah@crismonhotel.com <br />
                  abi@crismonhotel.com
                </p>
              </div>
            </div>
          </section>
        </section>

        <section className="md:mt-[100px] mt-[50px] pb-[40px] flex items-center md:justify-center px-[30px] justify-between md:gap-[200px] gap-[1rem] flex-wrap">
          <div className="flex gap-[37px] items-center">
            <Link to={"/privacy-policy"}>
              <p className="sm:text-[20px] text-[16px] poppins text-lightBrown tracking-[0.3px] leading-[28px] font-normal">
                Privacy Policy
              </p>
            </Link>
            <Link to={"/terms-and-conditions"}>
              <p className="sm:text-[20px] text-[16px] poppins text-lightBrown tracking-[0.3px] leading-[28px] font-normal">
                Terms & Conditions
              </p>
            </Link>
          </div>
          <div className="gap-[12px] flex items-center">
            <a
              href="https://www.tripadvisor.com/Hotel_Review-g1202780-d2578692-Reviews-Crismon_Hotel-Tema_Greater_Accra.html
            "
              target="__blank"
            >
              <img
                src="/images/tripadvisor.svg"
                alt="social-icon"
                className="cursor-pointer w-[24px] h-[24px]"
              />
            </a>
            <a
              href="https://web.facebook.com/crismonhotelgh/
            "
              target="__blank"
            >
              <img
                src="/images/facebook.svg"
                alt="social-icon"
                className="cursor-pointer w-[24px] h-[24px]"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/crismon-hotel-ghana/?originalSubdomain=gh
            "
              target="__blank"
            >
              <img
                src="/images/linkedin.svg"
                alt="social-icon"
                className="cursor-pointer w-[24px] h-[24px]"
              />
            </a>
            <a
              href="https://twitter.com/crismonhotelgh
            "
              target="__blank"
            >
              <img
                src="/images/twitter.svg"
                alt="social-icon"
                className="cursor-pointer w-[24px] h-[24px]"
              />
            </a>
            <a
              href="https://wa.me/233561633542
            "
              target="__blank"
            >
              <img
                src="/images/whatsapp.svg"
                alt="social-icon"
                className="cursor-pointer w-[24px] h-[24px]"
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UCIJ21cmdvVauIByMF_DdHHg?app=desktop
            "
              target="__blank"
            >
              <img
                src="/images/youtube.svg"
                alt="social-icon"
                className="cursor-pointer w-[24px] h-[24px]"
              />
            </a>
          </div>
        </section>
      </section>
    </section>
  );
};

export default NavMenu;
