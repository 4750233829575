import React from "react";
import Navbar from "../components/Navbar";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";

const PrivacyPolicy = () => {
  return (
    <section>
      <Navbar pageTitle={" Privacy Policy"} />
      <Header pageTitle={"Privacy Policy"} classTitle={"about-bg"} />

      <section className="padding mt-[4rem]">
        <p className="text-[16px] poppins text-darkBrown leading-[22px]">
          Certainly! Here’s a sample template for the privacy policy of Crismon
          Hotel:
        </p>
        <p className="text-[16px] poppins text-darkBrown leading-[22px] mt-[2rem]">
          <span className="font-semibold">Privacy Policy </span>
          This Privacy Policy explains how Crismon Hotel collects, uses, and
          protects the personal information of individuals who visit our website
          or use our services. By accessing our website or using our services,
          you agree to the terms and conditions of this Privacy Policy.
        </p>

        <p className="text-[16px] poppins text-darkBrown leading-[22px] mt-[2rem]">
          <span className="font-semibold">1. Information Collection: </span>
          1.1. We may collect personal information, such as name, contact
          details, and payment information when you make a reservation or
          interact with our website or services. 1.2. We may also collect
          non-personal information, such as demographic data, IP addresses,
          browser type, and other browsing information, to improve our website
          and services.
        </p>
        <p className="text-[16px] poppins text-darkBrown leading-[22px] mt-[2rem]">
          <span className="font-semibold">2. Use of Information: </span>
          2.1. We may use the collected information to process reservations,
          provide customer support, personalize user experiences, and improve
          our services. 2.2. We may also use the information to send promotional
          emails, updates, and marketing communications if you have provided
          your consent to receive such communications.
        </p>

        <p className="text-[16px] poppins text-darkBrown leading-[22px] mt-[2rem]">
          <span className="font-semibold">3. Information Sharing: </span>
          3.1. We may share personal information with third-party service
          providers who assist us in operating our business, such as payment
          processors, IT support, and marketing partners. 3.2. We may also share
          personal information to comply with legal obligations, protect our
          rights and property, and enforce our policies. 3.3. We do not sell,
          trade, or rent personal information to third parties for their
          marketing purposes.
        </p>

        <p className="text-[16px] poppins text-darkBrown leading-[22px] mt-[2rem]">
          <span className="font-semibold">4. Data Security: </span>
          4.1. We implement reasonable security measures to protect the
          confidentiality and integrity of personal information. However, no
          data transmission over the internet or electronic storage method is
          completely secure. 4.2. We cannot guarantee the absolute security of
          personal information, and you acknowledge and accept any associated
          risks.
        </p>

        <p className="text-[16px] poppins text-darkBrown leading-[22px] mt-[2rem]">
          <span className="font-semibold">5. Third-Party Websites: </span>
          5.1. Our website may contain links to third-party websites. We do not
          control or endorse the content or practices of these websites, and
          this Privacy Policy does not apply to them. We recommend reviewing the
          privacy policies of any third-party websites you visit.
        </p>

        <p className="text-[16px] poppins text-darkBrown leading-[22px] mt-[2rem]">
          <span className="font-semibold">6. Children’s Privacy: </span>
          6.1. Crismon Hotel does not knowingly collect personal information
          from individuals under the age of 18. If we become aware of any such
          data, we will take steps to delete it. Parents or guardians should
          contact us if they believe their child has provided personal
          information without their consent.
        </p>

        <p className="text-[16px] poppins text-darkBrown leading-[22px] mt-[2rem]">
          <span className="font-semibold">7. Your Choices: </span>
          7.1. You have the right to access, update, or request the deletion of
          your personal information. You can also choose to opt-out of receiving
          marketing communications. 7.2. You can exercise these rights by
          contacting us using the contact information provided below.
        </p>

        <p className="text-[16px] poppins text-darkBrown leading-[22px] mt-[2rem]">
          <span className="font-semibold">
            8. Changes to this Privacy Policy:{" "}
          </span>
          8.1. We may update this Privacy Policy from time to time. The revised
          version will be posted on our website with the effective date. We
          encourage you to review this Privacy Policy periodically for any
          changes.
        </p>
        <p className="text-[16px] poppins text-darkBrown leading-[22px] mt-[2rem]">
          <span className="font-semibold">9. Contact Us: </span>
          9.1. If you have any questions, concerns, or requests regarding this
          Privacy Policy or our privacy practices, please contact us at{" "}
          <Link to={"/"} className="text-orange hover:underline">
            https ://www.crismonhotel.com/contact
          </Link>
        </p>
      </section>
      <div className="mt-[5rem]"></div>
      <Footer />
    </section>
  );
};

export default PrivacyPolicy;
