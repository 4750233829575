import React from "react";
import Navbar from "../components/Navbar";
import Services from "../components/Services";
import Exceed from "../components/Exceed";
import Accordion from "../components/Accordion";
import Testimonials from "../components/Testimonials";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import Header from "../components/Header";
import AboutGallery from "../components/AboutGallery";
import News from "../components/News";

const About = () => {
  return (
    <div>
      <Navbar pageTitle={"About us"} />
      <Header classTitle={"about-bg"} pageTitle={"About Us"} />
      <AboutGallery />
      <Services />
      <News />
      <Exceed />
      <Accordion />
      <Testimonials />
      <Newsletter />
      <Footer />
    </div>
  );
};

export default About;
