import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import {
  About,
  Blog,
  Booking,
  BookingEmpty,
  Gallery,
  Home,
  MeetingsEvents,
  Restaurant,
} from "./pages";
import NewsDetails from "./pages/NewsDetails";
import RoomDetails1 from "./pages/RoomDetails1";
import RoomDetails2 from "./pages/RoomDetails2";
import RoomDetails3 from "./pages/RoomDetails3";
import RoomDetails4 from "./pages/RoomDetails4";
import RoomDetails5 from "./pages/RoomDetails5";
import RoomDetails6 from "./pages/RoomDetails6";
import RoomDetails7 from "./pages/RoomDetails7";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Terms from "./pages/Terms";
import Contact from "./pages/Contact";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/booking" element={<Booking />} />
        <Route path="/booking-empty" element={<BookingEmpty />} />
        <Route path="/restaurant" element={<Restaurant />} />
        <Route path="/meetings&events" element={<MeetingsEvents />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/blog" element={<Blog />} />
        <Route
          path="/rooms&suits/room-details-classic-landscape-room"
          element={<RoomDetails1 />}
        />
        <Route
          path="/rooms&suits/room-details-junior-suite"
          element={<RoomDetails2 />}
        />
        <Route
          path="/rooms&suits/room-details-deluxe-room"
          element={<RoomDetails3 />}
        />
        <Route
          path="/rooms&suits/room-details-twin-room"
          element={<RoomDetails4 />}
        />
        <Route
          path="/rooms&suits/room-details-classic-poolside-room"
          element={<RoomDetails5 />}
        />
        <Route
          path="/rooms&suits/room-details-standard-room"
          element={<RoomDetails6 />}
        />
        <Route
          path="/rooms&suits/room-details-executive-suite"
          element={<RoomDetails7 />}
        />
        <Route path="/news/:id" element={<NewsDetails />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<Terms />} />
        <Route path="/contact-us" element={<Contact />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
