import React from "react";
import Hero from "../components/Hero";
import Welcome from "../components/Welcome";
import Services from "../components/Services";
import Explore from "../components/Explore";
import Exceed from "../components/Exceed";
import Accordion from "../components/Accordion";
import Testimonials from "../components/Testimonials";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import FeaturedRooms from "../components/FeaturedRooms";

const Home = () => {
  return (
    <section>
      <Hero />
      <Welcome />
      <Services />
      <FeaturedRooms />
      <Explore />
      <Exceed />
      <Accordion />
      <Testimonials />
      <Newsletter />
      <Footer />
    </section>
  );
};

export default Home;
