import React from "react";

const Welcome = () => {
  return (
    <section className="padding py-[80px] flex text-center items-center justify-center flex-col">
      <img
        src="/images/logo-orange.svg"
        alt="logo"
        className="w-[115px] object-cover h-[101px]"
      />

      <p className="sm:mt-[80px] mt-[40px] poppins sm:text-[16px] text-[14px] leading-[19px] text-deepBrown font-normal">
        AKWAABA - WELCOME TO CRISMON HOTEL
      </p>

      <h1 className="sm:mt-[20px] mt-[10px] sm:text-[49px] text-[35px] text-orange mar font-normal sm:leading-[58px] leading-[40px]">
        A journey elevated through <br className="hidden md:flex" />
        unyielding dedication
      </h1>
      <p className="sm:mt-[42px] mt-[20px] sm:mb-[42px] mb-[20px] max-w-[830px] text-lightBrown text-[16px] poppins tracking-[0.3px]">
        We never hesitate in our support, and we never lose sight of our main
        mission: serving our clients. Our job is to always make it easy for
        clients to do business with us and strive not only to meet their needs
        but also to exceed their expectations.
      </p>

      <h2 className="text-[#000] poppins tracking-[0.3px] text-[16px] leading-[22px] font-semibold">
        CHRIS AFEDZI
      </h2>
      <p className="text-[#000] opacity-[0.6] italic poppins text-[16px] leading-[19px]">
        General manager
      </p>
    </section>
  );
};

export default Welcome;
