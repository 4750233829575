import { useState } from "react";
import { Link } from "react-router-dom";
import NavMenu from "./NavMenu";
import Form from "./Form";

const Hero = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  if (toggleMenu) {
    return <NavMenu setToggleMenu={setToggleMenu} />;
  }
  return (
    <section>
      <section className="hero-bg padding">
        <nav className="md:pt-[60px] sm:pt-[30px] pt-[1rem] flex items-start justify-between">
          <img
            src="/images/logo-white.svg"
            alt="logo"
            className="w-[147px] h-[130px] object-contain cursor-pointer"
          />
          <img
            src="/images/menu-icon.svg"
            alt="menu-icon"
            className="sm:w-[35px] w-[28px] h-[22px] object-contain cursor-pointer"
            onClick={() => setToggleMenu(true)}
          />
        </nav>

        <section className="mt-[100px] flex items-center flex-col justify-center">
          <h1 className="mt-[47px] md:text-[61px] sm:text-[50px] text-[30px] mar text-white md:leading-[73px] sm:leading-[60px] leading-[45px] font-normal mb-[20px] text-center">
            Where Luxury Unfolds and <br className="hidden md:flex" />
            Comfort Knows No Bounds
          </h1>

          <p className="text-center md:text-[20px] sm:text-[18px] text-[16px] poppins font-normal text-white opacity-[0.6] sm:leading-[28px] leading-[24px] tracking-[0.3px]">
            Step into the Drama of Opulence and Warmth in the Heart of Ghana.
            Your
            <br className="hidden md:flex" />
            Unforgettable Journey Begins Here
          </p>
          <div className="mt-[44px]  cursor-pointer">
            <Link to="/booking">
              <button className="sm:px-[16px] px-[20px] sm:py-[12px] py-[8px]  text-[18px] poppins leading-[24px] font-normal text-lightBrown rounded-[100px] bg-white md:w-[177px] w-full text-center hover:bg-orange hover:text-white transition">
                Book Now
              </button>
            </Link>
          </div>

          <div className="flex cursor-pointer items-center mt-[30px] gap-[8px]">
            <img
              src="/images/play-btn.svg"
              alt=""
              className="sm:w-[32px] w-[25px] sm:h-[32px] h-[25px]"
            />
            <p className="md:text-[20px] sm:text-[18px] text-[16px] poppins leading-[24px] text-white">
              Watch Video
            </p>
          </div>

          <div className="mt-[160px] mb-[76px] flex lg:gap-[64px] md:gap-[2rem] gap-[1.5rem] items-center flex-wrap justify-center">
            <p className="text-white poppins md:text-[20px] sm:text-[16px] text-[14px] italic font-normal tracking-[0.3px] p1">
              Unique Dishes
            </p>
            <p className="text-gray_100 poppins md:text-[20px] sm:text-[16px] text-[14px] italic font-normal tracking-[0.3px] p2">
              Book Events
            </p>
            <p className="text-gray_100 poppins md:text-[20px] sm:text-[16px] text-[14px] italic font-normal tracking-[0.3px] p3">
              Elevate your stay
            </p>
            <p className="text-gray_100 poppins md:text-[20px] sm:text-[16px] text-[14px] italic font-normal tracking-[0.3px] p4">
              Style and Comfort
            </p>
          </div>
        </section>
      </section>

      <Form />
    </section>
  );
};

export default Hero;
