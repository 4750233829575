import instance from "./api";

const handleSubscription = async (email, fname, lname) => {
    const request = {
        email,
        fname,
        lname
    }
    try {
        const response = await instance.post("/server/addsubscriber.php", request);
        return response.data.success; //boolean
    }
    catch (err) {
        return false;
    }
};

export default handleSubscription;