import React, { useState } from "react";
import handleSubscription from "../utils/handleSubscription";

const Newsletter = () => {
  const [email, setEmail] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(false);
  const [processing, setProcessing] = useState(false);

  const handleSubmit = async () => {
    setProcessing(true);
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (fname.length === 0 || lname.length === 0 || email.length === 0)
      setMessage("Please fill all fields to book.")
    else if (!(emailRegex.test(email)))
      setMessage("Email address in the wrong format. (eg. johndoe@gmail.com)")
    else {
      const status = await handleSubscription(email, fname, lname);
      if (status) {
        setStatus(true);
        setMessage("Thank you for subscribing to our newsletter. We will keep you updated on all the exciting news and offers.");
      }
      else
        setMessage("Subscription failed. Please try again later.")
    }
    setProcessing(false);
  }

  return (
    <section className="bg-[#F8F7F5] pt-[70px] pb-[59px] padding flex flex-col justify-center items-center">
      <p className="text-lightBrown uppercase leading-[19px] poppins font-normal text-center mb-[20px]">
        Stay in touch
      </p>

      <h1 className="text-darkBrown md:text-[49px] sm:text-[35px] text-[30px] md:leading-[58px] sm:leading-[46px] leading-[35px] mar mb-[20px] text-center">
        Subscribe to our Newsletter
      </h1>
      <p className="text-center text-lightBrown text-[16px] leading-[22px] tracking-[0.3px] mb-[58px]">
        {" "}
        never miss out on amazing specials and promotions to experience your
        favourite <br className="hidden md:flex" />
        journeys in the best Hotel.
      </p>

      {message.length > 0 && (
          <p className="text-orange w-full text-center pt-[30px]">{message}</p>
        )}
      {!status && (
        <>
          <div className="flex gap-[10px] sm:flex-row flex-col w-full">
            <input
              type="email"
              placeholder="Email Address*"
              className="border-b border-[#C5BFB1] bg-transparent outline-none sm:text-center py-2  w-full placeholder-[#A69C86] text-[16px] poppins"
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="text"
              placeholder="First Name"
              className="border-b border-[#C5BFB1] bg-transparent outline-none sm:text-center py-2  w-full placeholder-[#A69C86] text-[16px] poppins"
              onChange={(e) => setFname(e.target.value)}
            />
            <input
              type="text"
              placeholder="Last Name"
              className="border-b border-[#C5BFB1] bg-transparent outline-none sm:text-center py-2  w-full placeholder-[#A69C86] text-[16px] poppins"
              onChange={(e) => setLname(e.target.value)}
            />
          </div>

          <button
            className="px-[24px] py-[16px] bg-orange  rounded-[100px] mt-[38px] text-white text-[16px] poppins leading-[19px] hover:text-orange hover:bg-transparent hover:border border-orange"
            onClick={handleSubmit}
            disabled={processing}
          >
            {processing ? "Processing..." : "Subscribe"}
          </button>
        </>
      )}
    </section>
  );
};

export default Newsletter;
