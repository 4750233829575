import React from "react";
import { Link } from "react-router-dom";

const Exceed = () => {
  return (
    <section className="exceed-bg padding flex items-center flex-col justify-center text-center gap-[32px]">
      <h1 className="md:mt-[73px] mt-[40px] text-white md:text-[49px] sm:text-[35px] text-[30px] md:leading-[58px] sm:leading-[46px] leading-[35px] mar">
        Luxurious accommodations that will
        <br className="hidden md:flex" /> exceed your expectations
      </h1>

      <p className="max-w-[738px] text-white poppins tracking-[0.3px] leading-[22px] sm:text-[16px] text-[15px]">
        Secure your dream stay at Crismon Hotel! Book now to experience the
        comfort and elegance of our limited-availability rooms and suites. Don't
        miss out—reserve your spot before it's too late!
      </p>

      <Link to={"/booking"}>
        <button className=" text-center text-black border border-white px-[30px] py-[10px] text-[16px] rounded-[100px] bg-white hover:text-white hover:bg-transparent hover:border poppins transition mb-[60px]">
          Book Now
        </button>
      </Link>
    </section>
  );
};

export default Exceed;
