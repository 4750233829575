import React from "react";
import Navbar from "../components/Navbar";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import Form from "../components/Form";
import Header from "../components/Header";
import { Link } from "react-router-dom";

const BookingEmpty = () => {
  return (
    <div>
      <Navbar pageTitle={"Booking"} />
      <Header pageTitle={"Rooms/Suits Available"} classTitle={"booking-bg"} />
      <Form />
      <section className="padding py-[100px] bg-[#F0EEEB] flex flex-col justify-center items-center">
        <h2 className="text-center font-semibold poppins text-[20px] leading-[24px] mb-[8px] ">
          OOps! No Rooms Available
        </h2>

        <p className="max-w-[780px] text-center poppins text-lightBrown text-[16px] tracking-[0.3px] leading-[22px]">
          We’re sorry, but it seems all rooms are booked for the dates you’ve
          selected. You can try adjusting the date or{" "}
          <Link to={"/"} className="text-orange hover:underline">
            Contact Us
          </Link>{" "}
          for special and personalized assistance
        </p>
      </section>
      <Newsletter />
      <Footer />
    </div>
  );
};

export default BookingEmpty;
