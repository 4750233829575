import { useState } from "react";
import { useSpring, animated } from "react-spring";

const AccordionItem = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { height, opacity } = useSpring({
    from: { height: 0, opacity: 0 },
    to: { height: isOpen ? "auto" : 0, opacity: isOpen ? 1 : 0 },
  });

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border-b md:w-[750px] sm:w-[600px] xs:w-[400px] w-[250px] ">
      <div
        className="flex items-center py-4 justify-between cursor-pointer gap-[1rem] transition"
        onClick={toggleAccordion}
      >
        <h3 className="md:text-[20px] sm:text-[18px] text-[16px] font-semibold text-[#4F4F4F] poppins">
          {title}
        </h3>
        {isOpen ? (
          <img src="/images/minus.svg" alt="" className="w-[30px] h-[30px]" />
        ) : (
          <img src="/images/plus.svg" alt="" className="w-[30px] h-[30px]" />
        )}
      </div>
      <animated.div
        style={{ height, opacity }}
        className="overflow-hidden transition"
      >
        <p className="py-1 poppins sm:text-[16px] text-[14px] text-[#4F4F4F]">
          {content}
        </p>
      </animated.div>
    </div>
  );
};

const Accordion = () => {
  return (
    <section className="flex items-center justify-center flex-col padding py-[70px]">
      <p className="uppercase text-[16px] leading-[19px] poppins text-deepBrown mb-[20px] text-center">
        Frequently asked questions
      </p>
      <h1 className="text-orange md:text-[49px] sm:text-[35px] text-[30px] md:leading-[58px] sm:leading-[46px] leading-[35px] mar mb-[58px] text-center">
        Here are a few questions we get
        <br className="hidden md:flex" /> asked.
      </h1>

      <section>
        <AccordionItem
          content="Crismon Hotel offers a range of exquisitely furnished accommodations to suit every guest's needs, including: Standard Room, Classic Poolside Room, Twin Room, Deluxe Room, Junior Suite, Classic Landscape Room. Each room is designed with a different theme, so guests can choose the one that best fits their personal preferences and travel style."
          title={"What types of accommodation are available at Crismon Hotel?"}
        />

        <AccordionItem
          title={"What facilities and amenities are included with my stay?"}
          content="At Crismon Hotel, we provide our guests with a wide range of facilities and amenities to ensure a comfortable and enjoyable stay. Some of the amenities include free Wi-Fi, air conditioning, flat-screen TV, swimming pool, fitness center, spa, restaurant, bar, business center, and conference facilities. We strive to meet every guest's needs, whether they're here for business or leisure, to ensure a memorable experience."
        />
        <AccordionItem
          title={"Are there any dining options available at hotel?"}
          content={
            "Yes, Crismon Hotel has a restaurant that serves delicious local and international cuisine. We also have a bar where guests can enjoy drinks and socialize in a relaxed atmosphere. Our restaurant and bar are open daily, serving breakfast, lunch, and dinner. We strive to provide our guests with a dining experience that meets their needs and preferences"
          }
        />

        <AccordionItem
          title={
            "Is the hotel located in a convinient area and is transportation easily accessible?"
          }
          content={
            "Crismon Hotel is located in the convenient area of Community 5 in Tema, Accra, Ghana, making it easily accessible for transportation. You can find us on the map at this link: https://www.google.com.gh/maps/place/Crismon+Hotel/@5.6420678,-0.0266374,17z/data=!3m1!4b1!4m9!3m8!1s0xfdf874b748e077d:0xec125d417d9dd141!5m2!4m1!1i2!8m2!3d5.6420625!4d-0.0240625!16s%2Fg%2F11h77px45y"
          }
        />

        <AccordionItem
          title={
            "Does Crismon Hotel offer any business services such as conference rooms or meeting facilities?"
          }
          content={
            "Yes, Crismon Hotel offers a range of business services including conference rooms and meeting facilities to ensure the smooth running of your business needs during your stay with us. Our well-equipped meeting rooms and conference facilities are perfect for workshops, and business meetings. We strive to provide everything you need to make your business trip as productive and stress-free as possible."
          }
        />
      </section>
    </section>
  );
};

export default Accordion;
