import React, { useRef, useState } from "react";
import { rooms2 } from "../constants/rooms";
import emailjs from "emailjs-com";
import handleBooking from "../utils/handleBooking";

const FormMobile = () => {
  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [room, setRoom] = useState("");
  const [guests, setGuests] = useState(1);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(false);
  const [processing, setProcessing] = useState(false);
  const price = useRef(0);

  const today = new Date().toISOString().split("T")[0];

  const calculateDays = () => {
    const startDate = new Date(checkIn);
    const endDate = new Date(checkOut);

    // Calculate the difference in milliseconds
    const differenceInTime = endDate - startDate;

    // Convert the difference in milliseconds to days
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    return differenceInDays > 0 ? differenceInDays : 1;
  }

  const handleGuests = (value) => {
    if (value > rooms2[room].max_capacity)
      setGuests(rooms2[room].max_capacity);
    else
      setGuests(value);
  };

  const handleGuestsRoom = (value) => {
    if (guests > rooms2[value].max_capacity)
      setGuests(rooms2[value].max_capacity);
    else
      setGuests(guests);
  }

  const roomSelect = (value) => {
    setRoom(value);
    handleGuestsRoom(value);
  }

  const handleSubmit = async () => {
    setProcessing(true);
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phoneRegex = /^\+?[0-9]{10,}\/?[0-9]*$/;

    if (
      checkIn.length === 0 ||
      checkOut.length === 0 ||
      name.length === 0 ||
      email.length === 0 ||
      room.length === 0 ||
      guests.length === 0 ||
      tel.length === 0
    )
      setMessage("Please fill all fields to book.");
    else if (!emailRegex.test(email))
      setMessage("Email address in the wrong format. (eg. johndoe@gmail.com)");
    else if (parseInt(guests) < 1 || parseInt(guests) > 4)
      setMessage("Number of guests must be from 1 to 4.");
    else if (!phoneRegex.test(tel))
      setMessage("Phone number in the wrong format. (eg. 0200000002)");
    else if (tel.length < 10)
      setMessage("Inputted phone number is too short");
    else {
      const response = await handleBooking(name, email, tel, checkIn, checkOut, rooms2[room].name, guests, price.current);
      console.log(response);

      if (response != false) {
        setStatus(true);
        setMessage("Thanks for booking. A customer service representative will reach out soon.");
      }
      else {
        setMessage("Booking failed. Please try again later.");
      }
    }

    setProcessing(false);
  };

  const max_capacity = rooms2[room]?.max_capacity ?? 0;

  const numDays = calculateDays();
  const roomPrice = (rooms2[room]?.price ?? 0) + ((guests - 1) * 30);

  price.current = (numDays) * (roomPrice);

  return (
    <>
      {message.length > 0 && (
        <p className="text-orange w-full text-center pt-[30px]">{message}</p>
      )}
      {!status && (
        <section className="padding py-[30px] flex flex-col justify-center items-center m-auto w-full">
          <div className="flex items-center flex-col xs:flex-row justify-between w-full gap-[10px]">
            <div className="grid grid-cols-2 gap-[5px] w-full">
              <div className="relative w-full">
                <label
                  htmlFor="checkin"
                  className="text-[14px] poppins text-darkBrown tracking-[0.3px] leading-[20px] absolute top-[10px] left-[0.5rem] "
                >
                  Check In Date*
                </label>
                <input
                  type="date"
                  name="checkin"
                  min={today}
                  id="checkin"
                  className="border border-[#C5BFB1] text-[14px] rounded-[10px] h-[60px] pl-2 w-full pt-[1.5rem]"
                  onChange={(e) => setCheckIn(e.target.value)}
                />
              </div>
              <div className="relative w-full">
                <label
                  htmlFor="checkout"
                  className="text-[14px] poppins text-darkBrown tracking-[0.3px] leading-[20px] absolute top-[10px] left-[0.5rem] "
                >
                  Check Out Date*
                </label>
                <input
                  type="date"
                  name="checkout"
                  min={today}
                  id="checkout"
                  className="border border-[#C5BFB1] text-[14px] rounded-[10px] h-[60px] pl-2 w-full pt-[1.5rem]"
                  onChange={(e) => setCheckOut(e.target.value)}
                />
              </div>
            </div>
            <div className="relative w-full">
              <label
                htmlFor="name"
                className="text-[14px] poppins text-darkBrown tracking-[0.3px] leading-[20px] absolute top-[10px] left-[0.5rem] "
              >
                Full Name*
              </label>
              <input
                type="text"
                name="full name"
                id="name"
                className="border border-[#C5BFB1] text-[14px] rounded-[10px] h-[60px] pl-2 w-full pt-[1.5rem]"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="relative w-full">
              <label
                htmlFor="email"
                className="text-[14px] poppins text-darkBrown tracking-[0.3px] leading-[20px] absolute top-[10px] left-[0.5rem] "
              >
                Email*
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="border border-[#C5BFB1] text-[14px] rounded-[10px] h-[60px] pl-2 w-full pt-[1.5rem]"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="relative w-full">
              <label
                htmlFor="tele"
                className="text-[14px] poppins text-darkBrown tracking-[0.3px] leading-[20px] absolute top-[10px] left-[0.5rem] "
              >
                Telephone*
              </label>
              <input
                type="tel"
                name="tele"
                id="tele"
                className="border border-[#C5BFB1] text-[14px] rounded-[10px] h-[60px] pl-2 w-full pt-[1.5rem]"
                onChange={(e) => setTel(e.target.value)}
              />
            </div>
          </div>
          <div className="grid xs:grid-cols-2 w-full">
            <div className="relative w-full mt-[10px]">
              <label
                htmlFor="roomType"
                className="text-[14px] poppins text-darkBrown tracking-[0.3px] leading-[20px] absolute top-[10px] left-[0.5rem] "
              >
                Select Room Type*
              </label>
              <div>
                <select
                  name="roomType"
                  id="roomType"
                  className="border border-[#C5BFB1] text-[14px] rounded-[10px] h-[60px] pl-2 w-full pt-[1.5rem]"
                  onChange={(e) => roomSelect(e.target.value)}
                >
                  <option value="">Select a room</option>
                  {rooms2.map((room, index) => {
                    return (
                      <option key={index} value={index}>
                        {room.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="relative w-full mt-[10px]">
              <label
                htmlFor="guests"
                className="text-[14px] poppins text-darkBrown tracking-[0.3px] leading-[20px] absolute top-[10px] left-[0.5rem] "
              >
                Number of Guests*
              </label>
              <input
                type="number"
                name="guests"
                id="guests"
                value={guests}
                min={1}
                max={max_capacity}
                className="border border-[#C5BFB1] text-[14px] rounded-[10px] h-[60px] pl-2 w-full pt-[1.5rem]"
                onChange={(e) => handleGuests(e.target.value)}
                title={"Max number: " + max_capacity}
              />
            </div>
          </div>
          <p className="text-orange font-bold w-full text-center mt-6">Total cost: ${price.current}</p>
          <button
            className="text-white bg-orange px-[20px] py-[8px] rounded-[100px] text-[16px] poppins cursor-pointer hover:bg-dark_100 transition mt-[20px] flex m-auto"
            disabled={processing}
            onClick={handleSubmit}
          >
            {processing ? "Booking..." : "Book Now"}
          </button>
          <p className="text-orange w-full text-center pt-[20px]">Note: Each additional person incurs an extra $30 charge.</p>
        </section>
      )}
    </>
  );
};

export default FormMobile;
