import React from "react";
import Navbar from "../components/Navbar";
import Exceed from "../components/Exceed";
import Accordion from "../components/Accordion";
import Testimonials from "../components/Testimonials";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Events from "../components/Events";
const MeetingsEvents = () => {
  return (
    <div>
      <Navbar pageTitle={"Meetings & Events"} />
      <Header pageTitle={"Meetings & Events"} classTitle={"events-bg"} />
      <Events />
      <Exceed />
      <Accordion />
      <Testimonials />
      <Newsletter />
      <Footer />
    </div>
  );
};

export default MeetingsEvents;
