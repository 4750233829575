import React, { useRef } from "react";
import { bookingData } from "../constants/data";
import { Link } from "react-router-dom";

const FeaturedRooms = () => {
  const scrollRef = useRef(null);

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft -= 300; // Adjust the scroll distance as needed
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += 300; // Adjust the scroll distance as needed
    }
  };

  return (
    <section className="bg-light padding pb-[78px] ">
      <p className="pt-[78px] mb-[20px] uppercase text-lightBrown_100 sm:text-[16px] text-[14px] leading-[19px] poppins">
        Featured Rooms
      </p>
      <h2 className="text-orange sm:text-[49px] text-[35px] mar sm:leading-[58px]  leading-[35px] font-normal sm:pb-[60px] pb-[30px]">
        Rooms & Suites
      </h2>

      <section className="sm:pb-[5rem] pb-[2rem] relative">
        <div
          className="flex gap-[24px] overflow-auto scrollbar pb-[4rem]"
          ref={scrollRef}
        >
          {bookingData.map((item) => (
            <div key={item.id}>
              <div className="sm:w-[405px] w-[300px]">
                <img
                  src={item.image}
                  alt={item.title}
                  className="w-full sm:h-[366px] h-[230px] object-cover"
                />
                <div className="sm:px-[16px] sm:py-[20px] py-[10px]">
                  <div className="flex justify-between items-center flex-wrap sm:gap-[0] gap-[1rem]">
                    <p className="text-orange uppercase poppins sm:text-[25px] text-[20px] sm:leading-[30px] leading-[20px] w-full font-[500]">
                      {item.title}
                    </p>
                  </div>

                  <p className="opacity-[0.6] text-[#000] sm:text-[16px] text-[14px] poppins tracking-[0.3px] leading-[25px] underline sm:mt-[16px] mt-[10px] hover:opacity-[1] transition">
                    <Link to={item.path}>View Room</Link>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <section className="absolute w-full top-[170px]  ">
          <div className="flex items-center justify-between">
            <img
              src="/images/arrow1.svg"
              alt="arrow"
              className="w-[32px] h-[32px] cursor-pointer z-[10] lgg:ml-[-6rem] sm:ml-[-2rem]"
              onClick={scrollLeft}
            />
            <img
              src="/images/arrow2.svg"
              alt="arrow"
              className="w-[32px] h-[32px] cursor-pointer lgg:mr-[-6rem] sm:mr-[-2rem]"
              onClick={scrollRight}
            />
          </div>
        </section>
      </section>
    </section>
  );
};

export default FeaturedRooms;
