import React from "react";
import { TestimonialsData } from "../constants/data";

const Testimonials = () => {
  return (
    <section className="paddingLeft sm:pt-[82px] pt-[50px] pb-[52px] bg-light_100">
      <p className="uppercase sm:text-[16px] text-[15px] poppins text-deepBrown text-center sm:mb-[20px] mb-[15px]">
        Testimonials
      </p>

      <h1 className="text-darkBrown md:text-[49px] sm:text-[35px] text-[30px] md:leading-[58px] sm:leading-[46px] leading-[35px] mar md:mb-[80px] sm:mb-[40px]  mb-[20px] text-center">
        What our Customers think about
        <br className="hidden md:flex" /> Crismon Hotel
      </h1>

      <section className="flex items-start scrollbar overflow-auto pb-[60px]">
        {TestimonialsData.map((item) => (
          <div key={item.id}>
            <div className="sm:w-[450px] w-[300px] mr-[20px]">
              <div>
                {item.link && (
                  <p
                    className={
                      "p-[16px] text-[#4F4F4F] tracking-[0.3px] poppins text-[16px] leading-[22px] mb-[45px]"
                    }
                  >
                    {item.content}
                  </p>
                )}

                {item.linkR && (
                  <p
                    className={
                      "p-[16px] text-[#4F4F4F] tracking-[0.3px] poppins text-[16px] leading-[22px] mb-[22px]"
                    }
                  >
                    {item.content}
                  </p>
                )}

                {!item.link && !item.linkR && (
                  <p
                    className={
                      "p-[16px] text-[#4F4F4F] tracking-[0.3px] poppins text-[16px] leading-[22px]"
                    }
                  >
                    {item.content}
                  </p>
                )}
              </div>

              <div className="p-[16px] bg-[#D1CBC0] rounded-[16px]">
                <p className="text-darkBrown font-semibold leading-[22px] text-[16px] tracking-[0.3px] poppins">
                  {item.name}
                </p>
                <p className="text-lightBrown font-normal leading-[22px] text-[16px] tracking-[0.3px] poppins">
                  {item.title}
                </p>
              </div>
            </div>
          </div>
        ))}
      </section>
    </section>
  );
};

export default Testimonials;
